import Modal from '@root/src/components/modal/Modal'
import { ReactComponent as LogoWithText } from '@assets/images/common/logo_black_no_background.svg'
import { _t } from '@root/src/core/locales'
import { Form, Formik, FormikProps } from 'formik'
import { TypeSmsPopupFormValues } from '@root/src/core/types/payment'
import { smsPopupInitialValues, smsPopupValidationSchema } from '../utils'
import MobilePhoneInput from '@root/src/components/form/MobilePhoneInput'
import { useCallback, useState } from 'react'
import { SmsPopupFormEnum } from '@root/src/core/enums/payment'
import { useMemberUpdate } from '@root/src/core/api/queries/member'
import toast from 'react-hot-toast'
import { TypeMemberRequest } from '@root/src/core/types/memberRequest'
import { useSendMemberLinkViaSms } from '@root/src/core/api/queries/memberLink'

interface Props {
  paymentRequest: TypeMemberRequest
  onClose: () => void
}

const SmsPopup: React.FC<Props> = ({ paymentRequest, onClose }) => {
  const [isOpen, setIsOpen] = useState(true)
  const updateMemberPhone = useMemberUpdate()

  const sendMemberLinkViaSms = useSendMemberLinkViaSms()

  const handleUpdateMemberPhone = useCallback(
    (values: TypeSmsPopupFormValues) => {
      if (!paymentRequest?.member.id) return
      updateMemberPhone.mutate(
        {
          id: paymentRequest?.member.id,
          phone: values[SmsPopupFormEnum.Phone],
        },
        {
          onSuccess: () => {
            toast.success(_t('smsPopup_Done'), {
              id: 'member-phone-update-success',
            })
            sendMemberLinkViaSms.mutate()
            onClose()
            setIsOpen(false)
          },
          onError: () => {
            toast.error(_t('smsPopup_Error'), {
              id: 'member-phone-update-failed',
              duration: 2000,
            })
            onClose()
            setIsOpen(false)
          },
        },
      )
    },
    [
      onClose,
      paymentRequest?.member.id,
      sendMemberLinkViaSms,
      updateMemberPhone,
    ],
  )

  return (
    <Modal
      onClose={onClose}
      containerClassName='mx-4'
      open={isOpen}
      children={
        <div className='flex flex-col py-8 px-5 dynamic-align'>
          <LogoWithText width={120} height={40} className='mx-auto mb-12' />
          <h1 className='h4 text-[18px] font-extrabold text-grouper-purple mb-6'>
            {_t('smsPopup_Title')}
          </h1>
          <p className='h5 mb-4'>{_t('smsPopup_SubTitle')}</p>
          <p className='h6 mb-12'>{_t('smsPopup_Text')}</p>

          <Formik
            onSubmit={handleUpdateMemberPhone}
            validateOnMount={true}
            enableReinitialize={true}
            initialValues={smsPopupInitialValues}
            validationSchema={smsPopupValidationSchema}
          >
            {({ isValid }: FormikProps<TypeSmsPopupFormValues>) => (
              <Form className={'w-full'}>
                <MobilePhoneInput
                  className='mb-14'
                  id={SmsPopupFormEnum.Phone}
                  name={SmsPopupFormEnum.Phone}
                />
                <button
                  type='submit'
                  className='btn-base
                  bg-grouper-purple 
                  text-white 
                  mx-auto
                  px-14
                  rounded-full
                  shadow-none 
                  h-12 
                  font-poppins 
                  text-sm'
                  disabled={!isValid}
                >
                  {_t('paymentSmsPopUpUpdate')}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}

export default SmsPopup

export enum UnsubscribeReasonTypeEnum {
  PriceTooHigh = 'PT',
  DidntFindValue = 'DF',
  GotWhatNeeded = 'GW',
  Other = 'OT',
}

export enum MemberRequestTypeEnum {
  Payment = 'PY',
  Join_For_Free = 'JF',
  Free_Provider = 'FP',
  Change_Payment_Method = 'CP',
  Unsubscribe = 'UN',
}

import { TypeNotification } from '@customTypes/notification'
import { EnvironmentEnum } from '@enums/environment'
import { env } from '@src/env'

export const DEFAULT_CURRENCY_SYMBOL = '$'
export const DEFAULT_CURRENCY_ISO = 'USD'
export const FREE_TRIAL_DAYS = '3'
export const DESCRIPTION_LIMIT = 400

export const TELEGRAM_APP_LINK = 'https://onelink.to/hymj3w'

export const MOCK_NOTIFICATION: TypeNotification = {
  title: 'this is an exmaple of a notifiaction you will recieve from us',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
  date: 'Today, 20:30',
  hasRead: false,
}

const BOT_MEMBER_ID_ENUM = {
  [EnvironmentEnum.Local]: 'DVSDWn0uRi_MemberAssistantBot',
  [EnvironmentEnum.Development]: 'DVSDWn0uRi_MemberAssistantBot',
  [EnvironmentEnum.Staging]: 'STrZNer8XB_MemberAssistantBot',
  [EnvironmentEnum.Production]: 'ZigiBuddyBot',
}

export const BOT_MEMBER_ID = BOT_MEMBER_ID_ENUM[env]

export const SUPPORT_EMAIL = 'support@wearegrouper.com'

export const TermsAndConditionsLink =
  'https://wearegrouper.com/member/terms-and-conditions/'

export const HelpCenterLink = 'https://wearegrouper.com/help-center-member/'

export const PrivacyPolicyLink =
  'https://wearegrouper.com/member/privacy-policy/'

export const OPEN_TELEGRAM_APP_LINK_BASE = `tg://resolve`

export const OPEN_TELEGRAM_BROWSER_LINK_BASE = 'https://t.me/'

export const DOWNLOAD_TELEGRAM_DESKTOP_LINK = 'https://desktop.telegram.org/'

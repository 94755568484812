import { object, string } from 'yup'
import { TypeUnsubscribeReasonFormValues } from '@customTypes/memberRequest'
import { UnsubscribeReasonTypeEnum } from '@enums/memberRequest'
import { _t } from '@locales/index'

export const unsubscribeInitialValues: TypeUnsubscribeReasonFormValues = {
  otherDescription: _t('writeHere'),
}

export const unsubscribeValidationSchema = object().shape({
  unsubscribeReason: string().required().min(2),
  otherDescription: string().when('unsubscribeReason', {
    is: UnsubscribeReasonTypeEnum.Other,
    then: string().required().min(2).max(1000),
  }),
})

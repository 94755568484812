import React from 'react'
import { _t } from '@locales/index'

const UnsubscribeTitle = () => {
  return (
    <div className='flex items-center gap-3'>
      <div className='min-w-2 h-10 bg-custom-black self-stretch' />
      <div>
        <h1 className={'text-2xl text-custom-black'}>{_t('areYouLost')}</h1>
        <h1 className={'text-[22px] text-custom-black'}>
          {_t('unsubscribeNoCommunity')}
        </h1>
      </div>
    </div>
  )
}

export default UnsubscribeTitle

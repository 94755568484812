import React from 'react'
import { _t } from '@locales/index'
import { ReactComponent as BotIcon } from '@assets/images/common/bot-happy.svg'
import { MESSAGE_MEMBER_URL } from '@const/member'
import { BOT_MEMBER_ID } from '@const/common'

const MemberRequestExpired = () => {
  return (
    <div className='page justify-center'>
      <div className='pb-12'>
        <h1 className='title text-white font-poppins text-2xl capitalize text-center'>
          {_t('memberRequestExpiredHeader')}.
        </h1>
      </div>

      <div className='flex justify-center items-center'>
        <a
          href={MESSAGE_MEMBER_URL + BOT_MEMBER_ID}
          className='btn-base btn-default-contained bg-grouper-purple rounded-full border border-custom-black w-[275px] p-3 gap-3 button-shadow-1'
        >
          <div
            className={
              'rounded-full w-12 h-12 flex justify-center items-center'
            }
          >
            <BotIcon className={'w-12'} />
          </div>
          <span className='font-poppins text-xl text-white'>
            {_t('memberRequestExpiredButton')}
          </span>
        </a>
      </div>
    </div>
  )
}

export default MemberRequestExpired

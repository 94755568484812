import React, { useCallback } from 'react'
import Skeleton from '@components/skeleton/Skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { _t } from '@locales/index'
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik'
import {
  unsubscribeInitialValues,
  unsubscribeValidationSchema,
} from '@containers/memberRequests/utils'
import { TypeUnsubscribeReasonFormValues } from '@customTypes/memberRequest'
import { UnsubscribeReasonTypeEnum } from '@enums/memberRequest'
import { toast } from 'react-hot-toast'
import { useCreateCommunityUnsubscribe } from '@api/queries/payment'
import { TypeUnsubscribeCommunityRequest } from '@customTypes/payment'
import UseLanguage from '@hooks/language/useLanguage'

const UnsubscribeCommunity = () => {
  const { token } = useParams()
  const navigate = useNavigate()

  const { data: paymentRequest, isLoading: paymentLinkLoading } =
    useGetPaymentLink(token || '', {
      enabled: Boolean(token),
      onSuccess: memberRequest => {
        // Choose the current payment method
        if (memberRequest?.language) {
          UseLanguage(memberRequest.language)
        }
      },
    })

  const createCommunityUnsubscribeRequest = useCreateCommunityUnsubscribe({
    onError: error => {
      toast.error(error.message, { id: 'community-create-payment' })
      navigate(`/member_request/${token}/post-unsubscribe?error=True`)
    },
    onSuccess: () => {
      navigate(`/member_request/${token}/post-unsubscribe`)
    },
  })

  let handleUnsubscribe = useCallback(
    (values: TypeUnsubscribeReasonFormValues) => {
      const requestValues: TypeUnsubscribeCommunityRequest = {
        memberRequestId: token || '',
        reason: values.unsubscribeReason || UnsubscribeReasonTypeEnum.Other,
        otherDescription: values.otherDescription || '',
      }

      createCommunityUnsubscribeRequest.mutate(requestValues)
    },
    [createCommunityUnsubscribeRequest, token],
  )

  return (
    <div className='page'>
      {paymentLinkLoading && (
        <div className='flex flex-col gap-5'>
          <Skeleton className='!h-36' count={5} />
        </div>
      )}
      {paymentRequest && (
        <div>
          <div className='flex items-center gap-3'>
            <div className='min-w-[8px] bg-grouper-blue rounded-full self-stretch' />
            <div>
              <h1 className='title-lowercase text-[32px]'>
                {_t('unsubscribeCommunityHeader1')}
              </h1>
              <h1 className='title-lowercase text-[32px]'>
                {_t('unsubscribeCommunityHeader2')}
              </h1>
            </div>
          </div>

          <div className='h-12' />

          <div className='flex'>
            <div>
              <p className='title-lowercase text-[24px]'>
                {_t('unsubscribeCommunitySubHeader')}
              </p>
            </div>
          </div>
          <div className='h-4' />

          <div>
            <Formik
              initialValues={unsubscribeInitialValues}
              validateOnMount={true}
              onSubmit={handleUnsubscribe}
              validationSchema={unsubscribeValidationSchema}
            >
              {({
                isSubmitting,
                isValid,
                values,
              }: FormikProps<TypeUnsubscribeReasonFormValues>) => (
                <Form>
                  <div role='group'>
                    <label className='font-poppins text-lg flex items-center gap-3'>
                      <Field
                        type='radio'
                        name={'unsubscribeReason'}
                        value={UnsubscribeReasonTypeEnum.PriceTooHigh}
                      />
                      <span>{_t('unsubscribeCommunityPriceTooHigh')}</span>
                    </label>

                    <div className='h-4' />

                    <label className='font-poppins text-lg flex items-center gap-3'>
                      <Field
                        type='radio'
                        name={'unsubscribeReason'}
                        value={UnsubscribeReasonTypeEnum.DidntFindValue}
                      />
                      <span>{_t('unsubscribeCommunityDidntFindValue')}</span>
                    </label>

                    <div className='h-4' />

                    <label className='font-poppins text-lg flex items-center gap-3'>
                      <Field
                        type='radio'
                        name={'unsubscribeReason'}
                        value={UnsubscribeReasonTypeEnum.GotWhatNeeded}
                      />
                      <span>{_t('unsubscribeCommunityGotWhatINeed')}</span>
                    </label>

                    <div className='h-4' />

                    <label className='font-poppins text-lg flex items-center gap-3'>
                      <Field
                        type='radio'
                        name={'unsubscribeReason'}
                        value={UnsubscribeReasonTypeEnum.Other}
                      />
                      <span>{_t('unsubscribeCommunityOther')}</span>
                    </label>
                    {values.unsubscribeReason ===
                      UnsubscribeReasonTypeEnum.Other && (
                      <div className='flex-col justify-center items-center text-center'>
                        <div className='h-3' />
                        <Field
                          className='h-[100px] w-[335px] bg-custom-gray-6 border-none rounded'
                          type='text-area'
                          name={'otherDescription'}
                        />
                        <div className='h-3' />

                        <ErrorMessage
                          className='text-custom-red-2'
                          name={'otherDescription'}
                        />
                      </div>
                    )}
                  </div>

                  <div className='h-8' />

                  <button
                    type={'submit'}
                    className='btn-base bg-grouper-purple text-white h-[45px] w-[335px] mx-auto font-poppins gap-3'
                    disabled={!isValid || isSubmitting}
                  >
                    <span>{_t('unsubscribeCommunityButton')}</span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  )
}

export default UnsubscribeCommunity

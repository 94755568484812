import { useCallback, useEffect, useState } from 'react'
import { BreakpointsEnum } from '@enums/theme'
import { BREAKPOINTS } from '@const/theme'
import { TypeBreakpoint } from '@customTypes/theme'

const getDeviceConfig = (width: number): TypeBreakpoint => {
  if (width < BREAKPOINTS[BreakpointsEnum.sm]) {
    return BreakpointsEnum.sm
  } else if (
    width >= BREAKPOINTS[BreakpointsEnum.sm] &&
    width < BREAKPOINTS[BreakpointsEnum.md]
  ) {
    return BreakpointsEnum.md
  } else if (
    width >= BREAKPOINTS[BreakpointsEnum.md] &&
    width < BREAKPOINTS[BreakpointsEnum.lg]
  ) {
    return BreakpointsEnum.lg
  } else if (
    width >= BREAKPOINTS[BreakpointsEnum.lg] &&
    width < BREAKPOINTS[BreakpointsEnum.xl]
  ) {
    return BreakpointsEnum.xl
  } else {
    return BreakpointsEnum.dxl
  }
}

const useBreakpoint = (): TypeBreakpoint => {
  const [breakPoint, setBreakPoint] = useState<TypeBreakpoint>(() =>
    getDeviceConfig(window.innerWidth),
  )

  const calculateInnerWidth = useCallback(() => {
    setBreakPoint(getDeviceConfig(window.innerWidth))
  }, [])

  useEffect(() => {
    window.addEventListener('resize', calculateInnerWidth)
    return () => window.removeEventListener('resize', calculateInnerWidth)
  }, [calculateInnerWidth])

  return breakPoint
}
export default useBreakpoint

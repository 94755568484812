import React, { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTES } from '@const/routes'
import { useAuth0 } from '@auth0/auth0-react'
import { useGetCommunityByAffiliateToken } from '@api/queries/community'
import { CommunityAdminRoleEnum } from '@enums/community'
import {
  getCommunityMediaByType,
  getProfileMediaByType,
} from '@containers/profile/utils'
import { CommunityMediaTypeEnum, ProfileMediaTypeEnum } from '@enums/media'
import { DEFAULT_CURRENCY_ISO } from '@const/common'
import { Locale } from '@root/src/core/locales'
import Loader from '@components/loader/Loader'

const MemberAuth = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const currency = searchParams.get('currency')
  const product_price_id = searchParams.get('product_price_id')

  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const { data: community } = useGetCommunityByAffiliateToken(token || '', {
    enabled: Boolean(token),
    onError() {
      window.location.href = window.location.origin
    },
  })

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token)
      localStorage.setItem('currency', currency || DEFAULT_CURRENCY_ISO)
      localStorage.setItem('product_price_id', product_price_id || '')
    }
  }, [currency, product_price_id, token])

  const login_func = useCallback(async () => {
    if (token && community === undefined) return

    const owner = community?.admins.find(a => {
      if (a.role === CommunityAdminRoleEnum.Owner) return a
      else return null
    })

    const owner_img = getProfileMediaByType(
      ProfileMediaTypeEnum.Profile,
      owner?.profile?.media || [],
    )

    const community_img = getCommunityMediaByType(
      CommunityMediaTypeEnum.Cover,
      community?.media || [],
    )

    await loginWithRedirect({
      redirectUri: window.location.origin + ROUTES.memberCreate,
      entity: 'member',
      image_url: community_img?.at(0)?.url || owner_img?.at(0)?.url,
      language: community?.language || Locale.getLanguage(),
    })
  }, [community, loginWithRedirect, token])

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.memberCreate)
    } else {
      login_func().then()
    }
  }, [isAuthenticated, login_func, navigate, token])

  return <Loader visible={true} fullWidth={true} />
}

export default MemberAuth

import React, { useCallback, useMemo } from 'react'
import { _t, Locale } from '@locales/index'
import Skeleton from '@components/skeleton/Skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import JoinFreeFooter from '@containers/memberRequests/components/JoinFreeFooter'
import { useCreateCommunityJoinFree } from '@api/queries/productRequest'
import { TypeJoinFreeCommunityRequest } from '@customTypes/productRequest'
import {
  DEFAULT_CURRENCY_SYMBOL,
  OPEN_TELEGRAM_APP_LINK_BASE,
} from '@const/common'
import UseLanguage from '@hooks/language/useLanguage'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'
import { LanguagesSettings } from '@const/languages'
import { LanguagesDirection } from '@enums/language'
import { closeErrorPopup, openErrorPopup } from '@reducers/errorPopup'
import { useDispatch } from 'react-redux'

const JoinCommunityFree = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const language = Locale.getLanguage()
  const languageDirection = LanguagesSettings[language].direction
  const dispatch = useDispatch()
  const { data: paymentRequest, isLoading: paymentLinkLoading } =
    useGetPaymentLink(token || '', {
      enabled: Boolean(token),
      onSuccess: memberRequest => {
        // Choose the current payment method
        if (memberRequest?.language) {
          UseLanguage(memberRequest.language)
        }
      },
    })
  const openTelegramApp = useCallback(() => {
    window.open(OPEN_TELEGRAM_APP_LINK_BASE)
    dispatch(closeErrorPopup())
  }, [dispatch])

  const memberInCommunityEror = useCallback(() => {
    dispatch(
      openErrorPopup({
        visible: true,
        title: _t('errorWhoops'),
        description: _t('paymentFailMemberAlreadyInTheCommunity'),
        actionButtonLabel: _t('checkItOut'),
        onSubmit: openTelegramApp,
        type: 1,
      }),
    )
  }, [dispatch, openTelegramApp])

  const createCommunityJoinFree = useCreateCommunityJoinFree({
    onSuccess: () => {
      navigate(`/payment/done/${token}`)
    },
    onError: () => {
      memberInCommunityEror()
    },
  })

  const handleJoinFree = useCallback(() => {
    const values: TypeJoinFreeCommunityRequest = {
      memberRequestId: token || '',
    }

    createCommunityJoinFree.mutate(values)
  }, [createCommunityJoinFree, token])

  const calculateCommunities = useMemo(() => {
    const communities = paymentRequest?.numOfCommunities || null

    if (!communities || communities < 1) return 0
    else return communities + 1
  }, [paymentRequest])

  const calculateFreeSlots = useMemo(() => {
    const free_slots = paymentRequest?.numOfFreeSlots || null

    if (!free_slots || free_slots < 1) return 0
    else return free_slots - 1
  }, [paymentRequest])

  const breakpoint = useBreakpoint()
  const isMobile: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  const returnSlotsPhrase = useCallback(() => {
    if (!calculateFreeSlots)
      return `${_t('joinFreeContent4')}
          ${_t('more')} ${_t('joinFreeCommunities')} ${_t('joinFreeContent3')}`
    else if (languageDirection === LanguagesDirection.LTR) {
      return `${_t('joinFreeContent2')}
        ${calculateFreeSlots}  ${_t('more')} ${_t('joinFreeCommunity')} ${_t(
        'joinFreeContent3',
      )}`
    } else {
      return `${_t('joinFreeContent2')} ${_t('more')} ${_t(
        'joinFreeCommunity',
      )} ${calculateFreeSlots}   ${_t('joinFreeContent3')}`
    }
  }, [calculateFreeSlots, languageDirection])

  return (
    <div className='page'>
      {paymentLinkLoading && (
        <div className='flex flex-col gap-5'>
          <Skeleton className='!h-36' count={5} />
        </div>
      )}
      {paymentRequest && (
        <div>
          <div className='flex items-center gap-3'>
            <div className='min-w-[8px] bg-custom-black self-stretch' />
            <div>
              <h1 className='font-poppins text-3xl sm:text-4xl font-bold text-[32px]'>
                {_t('joinFreeHeader1')}
              </h1>
              <h1 className='font-poppins text-3xl sm:text-4xl font-bold text-[32px] text-pink-500'>
                {paymentRequest?.product?.name}
              </h1>
              <h1 className='font-poppins text-3xl sm:text-4xl font-bold text-[32px]'>
                {_t('joinFreeHeader2')}
              </h1>
            </div>
          </div>

          <div className='h-8' />
          <div className='flex justify-between border-x-0 border-y border-custom-gray-4 py-5'>
            <div>
              <p
                className={`font-poppins ${
                  isMobile ? 'font-lg' : 'text-[24px]'
                } font-bold`}
              >
                {_t('paymentCheckoutPrice')}
              </p>
            </div>
            <div className={'flex flex-col self-end items-end'}>
              <p className='font-poppins font-lg font-bold line-through text-gray-300'>
                {`${
                  paymentRequest?.memberCurrency?.symbol ||
                  DEFAULT_CURRENCY_SYMBOL
                }${
                  paymentRequest?.memberPrice ||
                  paymentRequest?.product?.price?.price
                } / ${_t('paymentCheckoutMonth')}`}
              </p>
              <div className='h-4' />
              <p className='font-poppins text-lg'>
                {_t('paymentCheckoutCancelAnyTime')}
              </p>
            </div>
          </div>
          <div className='h-8' />
          <div>
            <span className='font-poppins text-lg'>
              {_t('joinFreeContent1')}
            </span>
            <span className='font-poppins text-lg'>
              {`${calculateCommunities} ${_t('joinFreeCommunities')}.
              ${returnSlotsPhrase()}`}
            </span>
          </div>
          <div className='h-12' />
          <button
            className='btn-base btn-yellow-contained w-full shadow-none h-12 font-poppins font-bold text-sm'
            onClick={handleJoinFree}
          >
            {_t('joinFreeButton')}
          </button>
          <JoinFreeFooter paymentRequest={paymentRequest} />
        </div>
      )}
    </div>
  )
}

export default JoinCommunityFree

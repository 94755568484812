import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import MobileAfterTg from '@containers/telegramPage/components/mobile/mobileAfter'
import MobileBeforeTg from '@containers/telegramPage/components/mobile/mobileBefor'
import { telegramPageUtils } from '@containers/telegramPage/utils'
import { TELEGRAM_APP_LINK } from '@const/common'
import { useSearchParams } from 'react-router-dom'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { getProfileMediaByType } from '@containers/profile/utils'
import { ProfileMediaTypeEnum } from '@enums/media'
import LogoNoText from '@assets/images/common/logo-white.svg'

interface Props {}

const MobileLandingPage: React.FC<Props> = () => {
  const [searchParams] = useSearchParams()
  const start = searchParams.get('start')
  const domain = searchParams.get('domain')

  const { data: paymentRequest } = useGetPaymentLink(start || '', {
    enabled: Boolean(start),
    retry: 1,
  })

  const ownerProfileImageUrl = useMemo<string | undefined>(() => {
    if (paymentRequest?.owner?.media) {
      const media = getProfileMediaByType(
        ProfileMediaTypeEnum.Profile,
        paymentRequest.owner.media,
      )
      if (media) {
        return media?.[0]?.url || LogoNoText
      }
    } else return LogoNoText
  }, [paymentRequest])

  const [showCommunityPage, setShowCommunityPage] = useState(false)
  const downloadTelegram = useCallback(() => {
    telegramPageUtils.openInNewTab(TELEGRAM_APP_LINK)
    setTimeout(() => setShowCommunityPage(true), 2000)
  }, [setShowCommunityPage])

  const renderOptions = useMemo(() => {
    if (start && domain) {
      if (showCommunityPage)
        return <MobileAfterTg domain={domain} start={start} />
      else return <MobileBeforeTg clickTG={downloadTelegram} />
    }
  }, [start, domain, showCommunityPage, downloadTelegram])

  return (
    <div
      className={classNames(
        'bg-custom-black text-white w-[100vw] h-[100vh] flex flex-col items-center justify-center',
      )}
    >
      <div
        className={'w-[150px] h-[150px] border-2 rounded-full overflow-auto'}
      >
        <img
          src={ownerProfileImageUrl}
          className={'rounded-full w-full'}
          alt={'owner'}
        />
      </div>
      {renderOptions}
    </div>
  )
}

export default MobileLandingPage

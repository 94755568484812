import React from 'react'
import classNames from 'classnames'

interface Props {
  imageUrl?: string
  name?: string
  widthClass?: string
  textSizeClass?: string
}

const Avatar: React.FC<Props> = ({
  imageUrl,
  widthClass,
  name,
  textSizeClass,
}) => {
  return (
    <div
      className={classNames(
        'rounded-full overflow-hidden bg-custom-gray-2 border-white border flex justify-center items-center',
        widthClass || 'w-8 h-8',
      )}
    >
      {imageUrl && <img src={imageUrl || ''} alt={'U'} />}
      {!imageUrl && name && (
        <p
          className={classNames(
            'text-white font-poppins text-800 font-semibold uppercase',
            textSizeClass || 'text-2xl',
          )}
        >
          {name.substring(0, 1)}
        </p>
      )}
    </div>
  )
}

export default Avatar

export enum CommunityAdminRoleEnum {
  Owner = 'OW',
  Creator = 'CR',
  Guest = 'GU',
}

export enum CommunityMemberRoleEnum {
  Viewer = 'VI',
  Support = 'SU',
}

export enum CommunityApprovalStatusEnum {
  AwaitingReview = 'WR',
  Blocked = 'BL',
  Approved = 'AP',
}

export enum PublishCommunityFormEnum {
  PublishNow = 'PublishNow',
  SetLaunchDate = 'SetLaunchDate',
  LaunchDate = 'LaunchDate',
}

export enum CommunityProviderStatusEnum {
  Empty = 'EM',
  Processing = 'PR',
  Complete = 'CO',
}

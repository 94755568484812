import { _t, Locale } from '@locales/index'
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '@assets/images/common/arrow.svg'
import { Form, Formik, FormikProps } from 'formik'
import {
  paymentTncInitialValues,
  paymentTncValidationSchema,
} from '@containers/payment/utils'
import { TypePaymentTncFormValues } from '@customTypes/payment'
import { PrivacyPolicyLink, TermsAndConditionsLink } from '@const/common'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { getProfileMediaByType } from '@containers/profile/utils'
import { ProfileMediaTypeEnum } from '@enums/media'
import Avatar from '@components/avatar/Avatar'
import { PaymentProviderTypeEnum, PaymentTncFormEnum } from '@enums/payment'
import moment from 'moment'
import { useMemberUpdate } from '@api/queries/member'
import { toast } from 'react-hot-toast'
import { usePostMemberAgreementInfo } from '@api/queries/userAgreement'
import { UserAgreementStatusEnum } from '@enums/userAgreement'
import UAParser from 'ua-parser-js'
import 'moment-timezone'
import classNames from 'classnames'
import UseLanguage from '@hooks/language/useLanguage'
import { LanguagesSettings } from '@const/languages'
import { LanguagesDirection } from '@enums/language'
import EmailField from '@root/src/components/form/EmailField'

const PaymentTermsAndConditions = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const language = Locale.getLanguage()
  const languageDirection = LanguagesSettings[language].direction

  const { data: paymentRequest } = useGetPaymentLink(token || '', {
    enabled: Boolean(token),
    onSuccess: memberRequest => {
      // Choose the current payment method
      if (memberRequest?.language) {
        UseLanguage(memberRequest.language)
      }
    },
  })

  const postMemberAgreement = usePostMemberAgreementInfo()

  const updateMember = useMemberUpdate({
    onMutate: () => {
      toast.loading(_t('memberDobUpdateLoading'), {
        id: 'member-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'member-update' })
    },
  })

  const handleMemberAgree = useCallback(() => {
    const parser = new UAParser()
    const userDeviceInfo = parser.getResult()
    const timezone = moment.tz.guess()
    if (paymentRequest !== undefined) {
      const sendInfoObj = {
        memberId: paymentRequest?.member.id,
        status: UserAgreementStatusEnum.Approved,
        timezone: timezone,
        device: userDeviceInfo.device,
        additionalUserInfo: userDeviceInfo,
      }
      postMemberAgreement.mutate(sendInfoObj, {
        onSuccess: () => {},
      })
    }
  }, [paymentRequest, postMemberAgreement])

  const handleSubmit = useCallback(
    (values: TypePaymentTncFormValues) => {
      if (!paymentRequest?.member.id) return
      handleMemberAgree()
      updateMember.mutate(
        {
          id: paymentRequest?.member.id,
          email: values[PaymentTncFormEnum.Email],
        },
        {
          onSuccess: () => {
            toast.success(_t('memberDobUpdateSuccess'), {
              id: 'member-update',
            })

            const is_free = paymentRequest?.paymentProviders?.some(
              pp => pp.name === PaymentProviderTypeEnum.Free,
            )
            if (is_free) {
              navigate(`/payment/free/${token}`)
            } else {
              navigate(`/payment/checkout/${token}`)
            }
          },
        },
      )
    },
    [
      paymentRequest?.member.id,
      paymentRequest?.paymentProviders,
      handleMemberAgree,
      updateMember,
      navigate,
      token,
    ],
  )

  const ownerProfileImageUrl = useMemo<string | undefined>(() => {
    if (paymentRequest?.owner?.media) {
      const media = getProfileMediaByType(
        ProfileMediaTypeEnum.Profile,
        paymentRequest.owner.media,
      )
      if (media) {
        return media?.[0]?.url || ''
      }
    }
  }, [paymentRequest])

  return (
    <div className='page px-5 py-6'>
      <Formik
        initialValues={paymentTncInitialValues}
        onSubmit={handleSubmit}
        validationSchema={paymentTncValidationSchema}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid }: FormikProps<TypePaymentTncFormValues>) => (
          <Form>
            <Avatar imageUrl={ownerProfileImageUrl} widthClass={'w-[60px]'} />
            <div className='h-5' />
            <div className='flex items-center gap-3'>
              <div className='min-w-[8px] rounded-2xl bg-custom-black self-stretch' />
              <h1 className='font-inter font-bold text-[32px]'>
                {_t('paymentTncTitle')}
              </h1>
            </div>
            <div className='h-24' />
            <EmailField
              id={PaymentTncFormEnum.Email}
              name={PaymentTncFormEnum.Email}
              placeholder={'Enter your Email address'}
            />
            <div className='h-48' />

            <button
              type={'submit'}
              className={classNames(
                'btn-base rounded-3xl bg-grouper-blue shadow-none py-6 flex h-[42px] w-[107px] mx-auto text-xl font-bold font-poppins gap-1',
              )}
              disabled={isSubmitting || !isValid}
            >
              <span className='text-white'>{_t('join')}</span>
              <ArrowIcon
                fill={'#FFFFFF'}
                className={classNames(
                  'h-4',
                  languageDirection === LanguagesDirection.RTL
                    ? 'rotate-180'
                    : '',
                )}
              />
            </button>
            <div className='h-6' />
            <p className='text-center capitalize'>
              {<span>{_t('paymentTncContent3')}</span>}

              {
                <a
                  className='text-black font-bold underline'
                  href={TermsAndConditionsLink}
                >
                  {_t('paymentTermsOfUse')}
                </a>
              }
              {` & `}
              {
                <a
                  className='text-black font-bold underline'
                  href={PrivacyPolicyLink}
                >
                  {_t('privacyPolicy')}
                </a>
              }
            </p>
            <div className='h-6' />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PaymentTermsAndConditions

import React, { Fragment, useRef } from 'react'
import { ReactComponent as CrossIcon } from '@assets/images/common/cross-thin.svg'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'

interface Props {
  open: boolean
  showClose?: boolean
  onClose?: () => void
  backgroundClassName?: string
  containerClassName?: string
  mainContainerClassName?: string
  children: React.ReactNode
}

const Modal: React.FC<Props> = ({
  open,
  showClose = false,
  onClose,
  backgroundClassName,
  children,
  containerClassName,
  mainContainerClassName,
}) => {
  const focusRef = useRef(null)

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => {}}
        initialFocus={focusRef}
        open={open}
      >
        {/* backdrop */}
        <div
          className={classNames(
            'fixed inset-0 bg-black/30',
            backgroundClassName,
          )}
          aria-hidden='true'
        />
        {/* main container */}
        <div
          className={classNames(
            'fixed inset-0 overflow-y-auto',
            mainContainerClassName,
          )}
        >
          <div className='flex min-h-full items-center justify-center text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='translate-x-full opacity-0 scale-95'
              enterTo='translate-x-0 opacity-100 scale-100'
              leave='ease-in duration-300'
              leaveFrom='translate-x-0 opacity-100 scale-100'
              leaveTo='translate-x-full opacity-0 scale-95'
            >
              <Dialog.Panel
                className={classNames(
                  'w-auto transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all relative',
                  containerClassName,
                )}
              >
                <div className='hidden' ref={focusRef} />
                {/*body*/}
                {children}
                {showClose && onClose && (
                  <div
                    className='flex justify-center items-center w-8 h-8 rounded-full bg-white absolute right-5 top-5 sm:right-0 sm:top-0 sm:-right-12 z-50 cursor-pointer'
                    onClick={onClose}
                  >
                    <CrossIcon className='w-4 h-4' stroke='black' />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal

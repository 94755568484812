import { TypeAuth0Config } from '@customTypes/auth0'
import { EnvironmentEnum } from '@enums/environment'
import { env } from '@src/env'

const localConfig: TypeAuth0Config = {
  domain: 'dev-2-q3h936.us.auth0.com',
  clientId: 'XNRTuDf969gEvPr7AuQk0GdmwhqBseqY',
  audience: 'https://dev-2-q3h936.us.auth0.com/api/v2/',
}

const developmentConfig: TypeAuth0Config = {
  domain: 'dev-2-q3h936.us.auth0.com',
  clientId: 'XNRTuDf969gEvPr7AuQk0GdmwhqBseqY',
  audience: 'https://dev-2-q3h936.us.auth0.com/api/v2/',
}

const stagingConfig: TypeAuth0Config = {
  domain: 'zigi-accounts-staging.us.auth0.com',
  clientId: 'sA1LZjOqFLr5ErYk3ijRWBPVh4E9wElx',
  audience: 'https://zigi-accounts-staging.us.auth0.com/api/v2/',
}

const productionConfig: TypeAuth0Config = {
  domain: 'zigi-accounts.us.auth0.com',
  clientId: 'FFK9fApgw5powyBVnfBjpsLESqctw3cV',
  audience: 'https://zigi-accounts.us.auth0.com/api/v2/',
}

const config = {
  [EnvironmentEnum.Local]: localConfig,
  [EnvironmentEnum.Development]: developmentConfig,
  [EnvironmentEnum.Staging]: stagingConfig,
  [EnvironmentEnum.Production]: productionConfig,
}

export const auth0Config = config[env]

import { _t } from '@locales/index'
import TelegramIcon from '@assets/images/common/telegram-circle.png'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'

const PaymentRequestDone = () => {
  const { token } = useParams()

  const { data: paymentRequest } = useGetPaymentLink(token || '', {
    enabled: Boolean(token),
  })

  const generateUrl = useCallback(() => {
    return paymentRequest?.member?.link?.replace(
      'GrouperBuddyBot',
      paymentRequest?.member?.userBot || 'GrouperBuddyBot',
    )
  }, [paymentRequest?.member?.link, paymentRequest?.member?.userBot])

  return (
    <div className='page bg-custom-yellow flex-col justify-center'>
      <h2 className='h2 font-bold uppercase'>{_t('done')}.</h2>
      <div className='h-5' />
      <h3 className='h3 font-bold'>{_t('goBackTo')}</h3>
      <h3 className='h3 font-bold text-custom-pink'>{_t('yourBuddyBot')}</h3>
      <div className='h-13' />
      <a
        className='btn btn-rounded btn-lg bg-white rounded-full border border-custom-black w-[275px] justify-start'
        href={generateUrl()}
      >
        <img src={TelegramIcon} alt='telegram' className='w-12 h-12' />
        <span className='body1'>{_t('grouperBuddyBot')}</span>
      </a>
    </div>
  )
}

export default PaymentRequestDone

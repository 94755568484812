import { combineReducers } from '@reduxjs/toolkit'
import drawerMenuReducer from '@reducers/drawerMenu'
import errorPopupReducer from '@reducers/errorPopup'
import authMemberReducer from '@reducers/authMember'

const AppReducers = combineReducers({
  drawerMenu: drawerMenuReducer,
  errorPopup: errorPopupReducer,
  authMember: authMemberReducer,
})

export const rootReducer = (state: any, action: any) => {
  return AppReducers(state, action)
}

import React, { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { AppRoutes } from '@routes/main'
import { apiConfig } from '@src/core/api/apiConfig'
import { apiKeys } from '@src/core/api/apiKeys'
import apiService from '@services/api/apiService'
import { Toaster } from 'react-hot-toast'
import { useGetAccessToken } from '@src/core/api/queries/auth'
import { useGetMyMember } from '@api/queries/member'
import ErrorPopUp from '@components/errorPopup/ErrorPopup'
import { useAppSelector } from '@hooks/store/redux'
import { selectErrorPopupState } from '@selectors/errorPopup'

const App = () => {
  const routes = useRoutes(AppRoutes)
  const errorPopupState = useAppSelector(selectErrorPopupState)
  useGetMyMember()
  useGetAccessToken()
  useEffect(() => {
    apiService.init(apiConfig, apiKeys)
  }, [])

  return (
    <div className='App'>
      <Toaster
        position={'bottom-right'}
        containerStyle={{
          right: 40,
          bottom: 30,
        }}
      />
      {routes}
      <ErrorPopUp {...errorPopupState} />
    </div>
  )
}

export default App

import {
  TypePaymentTncFormValues,
  TypeSmsPopupFormValues,
} from '@customTypes/payment'
import { object, string } from 'yup'
import {
  PaymentDoneFormEnum,
  PaymentTncFormEnum,
  SmsPopupFormEnum,
} from '@enums/payment'

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const paymentTncInitialValues: TypePaymentTncFormValues = {
  [PaymentTncFormEnum.Email]: '',
}

export const smsPopupInitialValues: TypeSmsPopupFormValues = {
  [SmsPopupFormEnum.Phone]: '',
}

export const paymentTncValidationSchema = object().shape({
  [PaymentTncFormEnum.Email]: string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
})

export const paymentDoneValidationSchema = object().shape({
  [PaymentDoneFormEnum.MemberEmail]: string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  [PaymentDoneFormEnum.MemberPhone]: string()
    .required()
    .matches(phoneRegExp, 'Phone number is not valid'),
})

export const smsPopupValidationSchema = object().shape({
  [SmsPopupFormEnum.Phone]: string()
    .required()
    .matches(phoneRegExp, 'Phone number is not valid'),
})

import Modal from '@root/src/components/modal/Modal'
import { ReactComponent as LogoWithText } from '@assets/images/common/logo_black_no_background.svg'
import { _t } from '@root/src/core/locales'
import { Form, Formik, FormikProps } from 'formik'
import { TypePaymentDoneFormValues } from '@root/src/core/types/payment'
import { paymentDoneValidationSchema } from '../utils'
import MobilePhoneInput from '@root/src/components/form/MobilePhoneInput'
import { useCallback, useMemo } from 'react'
import { PaymentDoneFormEnum } from '@root/src/core/enums/payment'
import {
  useMemberUpdate,
  useSendSubscriptionEmail,
} from '@root/src/core/api/queries/member'
import toast from 'react-hot-toast'
import { TypeMemberRequest } from '@root/src/core/types/memberRequest'
import { useSendMemberLinkViaSms } from '@root/src/core/api/queries/memberLink'
import EmailField from '@root/src/components/form/EmailField'

interface Props {
  paymentRequest: TypeMemberRequest
  isOpen: boolean
  onClose: () => void
}

const UpdateDetailsPopup: React.FC<Props> = ({
  paymentRequest,
  isOpen,
  onClose,
}) => {
  const paymentDoneInitialValues = useMemo<TypePaymentDoneFormValues>(() => {
    return {
      [PaymentDoneFormEnum.MemberEmail]: paymentRequest?.member?.email || '',
      [PaymentDoneFormEnum.MemberPhone]: paymentRequest?.member?.phone || '',
    }
  }, [paymentRequest?.member])

  const updateMemberEmail = useMemberUpdate({
    onMutate: () => {
      toast.loading(_t('memberEmailUpdateLoading'), {
        id: 'member-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'member-update' })
    },
  })
  const updateMemberPhone = useMemberUpdate({
    onMutate: () => {
      toast.loading(_t('memberPhoneUpdateLoading'), {
        id: 'member-update',
      })
    },
    onError: error => {
      toast.error(error.message, { id: 'member-update' })
    },
  })

  const sendMemberLinkViaSms = useSendMemberLinkViaSms()
  const sendSubscriptionEmail = useSendSubscriptionEmail()

  const handleUpdateMemberDetails = useCallback(
    (values: TypePaymentDoneFormValues) => {
      if (!paymentRequest?.member.id || !paymentRequest?.product?.communityId)
        return

      updateMemberPhone.mutate(
        {
          id: paymentRequest?.member.id,
          phone: values[PaymentDoneFormEnum.MemberPhone],
        },
        {
          onSuccess: () => {
            toast.success(_t('memberPhoneUpdateSuccess'), {
              id: 'member-update',
            })
            sendMemberLinkViaSms.mutate()
          },
        },
      )

      updateMemberEmail.mutate(
        {
          id: paymentRequest?.member.id,
          email: values[PaymentDoneFormEnum.MemberEmail],
        },
        {
          onSuccess: () => {
            toast.success(_t('memberEmailUpdateSuccess'), {
              id: 'member-update',
            })
            sendSubscriptionEmail.mutate({
              memberId: paymentRequest?.member.id,
              communityId: paymentRequest?.product?.communityId || '',
            })
          },
        },
      )

      onClose()
    },
    [
      onClose,
      paymentRequest,
      sendMemberLinkViaSms,
      sendSubscriptionEmail,
      updateMemberEmail,
      updateMemberPhone,
    ],
  )

  return (
    <Modal
      showClose={true}
      onClose={onClose}
      containerClassName='mx-4'
      open={isOpen}
      children={
        <div className='flex flex-col py-8 px-5'>
          <LogoWithText width={120} height={40} className='mx-auto mb-12' />
          <h1 className='body1 mb-8'>{_t('updateDetailsPopup_Header')}</h1>

          <Formik
            onSubmit={handleUpdateMemberDetails}
            validationSchema={paymentDoneValidationSchema}
            initialValues={paymentDoneInitialValues}
            validateOnMount={true}
            enableReinitialize
          >
            {({
              values,
              dirty,
              isValid,
            }: FormikProps<TypePaymentDoneFormValues>) => (
              <Form>
                <EmailField
                  id={PaymentDoneFormEnum.MemberEmail}
                  name={PaymentDoneFormEnum.MemberEmail}
                />
                <div className='h-2' />
                <MobilePhoneInput
                  className='mb-14'
                  id={PaymentDoneFormEnum.MemberPhone}
                  name={PaymentDoneFormEnum.MemberPhone}
                />
                <div className='flex flex-col px-14 gap-4'>
                  <button
                    disabled={!dirty || !isValid}
                    className='btn-base rounded-full bg-grouper-purple h-12 text-white'
                    type='submit'
                    onClick={() => handleUpdateMemberDetails(values)}
                  >
                    {_t('updateDetailsPopup_TryAgain')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}

export default UpdateDetailsPopup

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import {
  TypeChangePaymentMethodRequest,
  TypeChangePaymentMethodResponse,
  TypeCommunityCouponCode,
  TypeConnectPayPalPayment,
  TypeStripeSetupIntentRequest,
  TypeStripeSetupIntentResponse,
  TypeSuccessPaypalConnectResponse,
  TypeSuccessPaypalDeleteResponse,
  TypeUnsubscribeCommunityRequest,
  TypeUnsubscribeResponse,
} from '@customTypes/payment'
import { AxiosError } from 'axios'
import { useProtection } from '@hooks/auth/useProtection'
import { ServerStateKeysEnum } from '@enums/serverState'
import queryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'

/**********************************
 CONNECT PAYPAL PAYMENT
 **********************************/

type TypeConnectPayPalPaymentOptions = UseMutationOptions<
  TypeSuccessPaypalConnectResponse,
  AxiosError,
  TypeConnectPayPalPayment
>

const connectPayPalPayment = async (
  values: TypeConnectPayPalPayment,
): Promise<TypeSuccessPaypalConnectResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.connect_paypal,
      values,
    )
    return response?.data
  } catch (err) {
    console.log('in identity err', err)
    throw err
  }
}

export const useConnectPayPalPayment = (
  options?: TypeConnectPayPalPaymentOptions,
) => {
  return useMutation(connectPayPalPayment, {
    ...options,
  })
}

/**********************************
 DELETE PAYPAL PAYMENT
 **********************************/

type TypeDeletePaymentOptions = UseMutationOptions<
  TypeSuccessPaypalDeleteResponse,
  AxiosError,
  string
>

export const deletePayPalPayment = async (
  id: string,
): Promise<TypeSuccessPaypalDeleteResponse> => {
  try {
    const response = await apiService.deleteUrl(
      ApiKeyNameEnum.delete_paypal,
      id,
    )
    return response?.data
  } catch (err) {
    throw err
  }
}

export const useDeletePayPalPayment = (options?: TypeDeletePaymentOptions) => {
  const queryOptions = useProtection<TypeDeletePaymentOptions>(options)

  return useMutation(deletePayPalPayment, {
    ...queryOptions,
  })
}

/**********************************
 STRIPE SETUP INTENT
 **********************************/

type TypeCreateStripeSetupIntentOptions = UseMutationOptions<
  TypeStripeSetupIntentResponse,
  AxiosError,
  TypeStripeSetupIntentRequest
>

const createStripeSetupIntent = async (
  values: TypeStripeSetupIntentRequest,
): Promise<TypeStripeSetupIntentResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.stripe_setup_intent,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateStripeSetupIntent = (
  options?: TypeCreateStripeSetupIntentOptions,
) => {
  return useMutation(createStripeSetupIntent, {
    ...options,
  })
}

/**********************************
 UNSUBSCRIBE COMMUNITY
 **********************************/

type TypeCreateCommunityUnsubscribeOptions = UseMutationOptions<
  TypeUnsubscribeResponse,
  Error,
  TypeUnsubscribeCommunityRequest
>

const createCommunityUnsubscribeRequest = async (
  values: TypeUnsubscribeCommunityRequest,
): Promise<TypeUnsubscribeResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.community_unsubscribe_request,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateCommunityUnsubscribe = (
  options?: TypeCreateCommunityUnsubscribeOptions,
) => {
  return useMutation(createCommunityUnsubscribeRequest, {
    ...options,
  })
}

/**********************************
 CHANGE PAYMENT METHOD
 **********************************/

type TypeChangePaymentMethodOptions = UseMutationOptions<
  TypeChangePaymentMethodResponse,
  Error,
  TypeChangePaymentMethodRequest
>

const createChangePaymentMethodRequest = async (
  values: TypeChangePaymentMethodRequest,
): Promise<TypeChangePaymentMethodResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.change_payment_method,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useChangePaymentMethod = (
  options?: TypeChangePaymentMethodOptions,
) => {
  return useMutation(createChangePaymentMethodRequest, { ...options })
}

/**********************************
 CHECKING COMMUNITY COUPON CODE
 **********************************/

type TypeCouponValidStatusOptions = UseQueryOptions<
  TypeCommunityCouponCode | false,
  AxiosError,
  TypeCommunityCouponCode | false,
  any
>

const getCouponValidStatus = async (
  code: string | undefined,
  communityId: string,
  timezone: string,
): Promise<TypeCommunityCouponCode> => {
  try {
    const qs = {
      code,
      communityId,
      timezone,
    }
    const queryParams = queryString.stringify(snakecaseKeys(qs), {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true,
    })
    const response = await apiService.get(
      ApiKeyNameEnum.community_coupon_code_check,
      '?' + queryParams,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCheckingCommunityCouponCode = (
  code: string | undefined,
  communityId: string,
  timezone: string,
  options?: TypeCouponValidStatusOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.Community, code, communityId],
    () => getCouponValidStatus(code, communityId, timezone),
    {
      select: data => {
        return data
      },
      ...options,
    },
  )
}

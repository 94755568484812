import { TypeApiKeys } from '@customTypes/api'
import { ApiKeyNameEnum } from '@enums/api'

export const apiKeys: TypeApiKeys = {
  // profile
  [ApiKeyNameEnum.profile]: '/profile/',
  [ApiKeyNameEnum.my_profile]: '/profile/me',

  // community
  [ApiKeyNameEnum.community]: '/community/',
  [ApiKeyNameEnum.communities]: '/community/all',
  [ApiKeyNameEnum.publish_community]: '/community/publish/',
  [ApiKeyNameEnum.community_affiliate_token]: '/community/affiliate_link/',

  // members
  [ApiKeyNameEnum.member]: '/member/',
  [ApiKeyNameEnum.my_member]: '/member/me',
  [ApiKeyNameEnum.community_members]: '/community/members/',
  [ApiKeyNameEnum.members_count]: '/community/members_count/',
  [ApiKeyNameEnum.member_subscribed_email]: '/member/subscribed_email',

  //launch_date
  [ApiKeyNameEnum.launch_date]: '/community/launch_date/',

  // media
  [ApiKeyNameEnum.media]: '/media/',
  [ApiKeyNameEnum.user_media]: '/media/user_media/',
  [ApiKeyNameEnum.media_create]: '/media/create/',
  [ApiKeyNameEnum.media_pre_upload]: '/media/pre_upload/',

  // interests
  [ApiKeyNameEnum.main_interests]: '/common/interests/main',
  [ApiKeyNameEnum.related_interests]: '/common/interests/related/',
  [ApiKeyNameEnum.parent_interests]: '/common/interests/parents/',
  [ApiKeyNameEnum.search_interests]: '/common/interests/search/',
  [ApiKeyNameEnum.community_interests]: '/community/interests/',
  [ApiKeyNameEnum.profile_interests]: '/profile/interests/',

  //locations
  [ApiKeyNameEnum.search_locations]: '/common/location/',

  // guidelines
  [ApiKeyNameEnum.community_guidelines]: '/community/guidelines/',
  [ApiKeyNameEnum.template_guidelines]: '/common/guideline_templates/',

  //paypal payment
  [ApiKeyNameEnum.create_paypal_payment]: '/payment/paypal/payout/',
  [ApiKeyNameEnum.delete_paypal]: '/payment/payout/',
  [ApiKeyNameEnum.connect_paypal]: '/payment/paypal/identity/',

  // payment
  [ApiKeyNameEnum.stripe_setup_intent]: '/payment/stripe/setup_intent/',
  [ApiKeyNameEnum.community_unsubscribe_request]:
    '/payment/community/unsubscribe/',
  [ApiKeyNameEnum.change_payment_method]: '/payment/change_payment_method/',

  // member
  [ApiKeyNameEnum.member_request]: '/member/request/',
  [ApiKeyNameEnum.payment_details]: '/payment/payment_details/',
  [ApiKeyNameEnum.available_funds]: '/payment/available_funds/',
  [ApiKeyNameEnum.billing_transactions]: '/payment/billing_transaction/',

  // payout
  [ApiKeyNameEnum.payout_settings]: '/payment/payout/',

  //contact_us
  [ApiKeyNameEnum.contact_us]: '/common/contact_us/',

  //coupons
  [ApiKeyNameEnum.get_coupon_list]: '/payment/coupon/',
  [ApiKeyNameEnum.create_coupon]: '/payment/coupon/',
  [ApiKeyNameEnum.delete_coupon]: '/payment/coupon/',
  [ApiKeyNameEnum.validate_coupon_code]: '/payment/coupon/validate_code/',

  //contact_us
  [ApiKeyNameEnum.change_password]: '/user/change_password/',

  //product_request
  [ApiKeyNameEnum.community_payment_request]: '/product_request/community/pay/',
  [ApiKeyNameEnum.community_join_free_request]:
    '/product_request/community/join_free/',
  [ApiKeyNameEnum.community_coupon_code_check]:
    '/payment/coupon/validate_coupon/',

  //user agreement
  [ApiKeyNameEnum.user_agreement]: '/agreement/user_agreement/',
  [ApiKeyNameEnum.user_agreement_for_member]:
    '/agreement/user_agreement/member/',

  // member_link
  [ApiKeyNameEnum.member_link]: '/member/member_link/',
  [ApiKeyNameEnum.send_member_link_via_sms]: '/member/member_link/send/sms/',
}

import React from 'react'
import Logo from '@containers/navbar/components/Logo'
import { _t } from '@locales/index'

interface Props {}

const ErrorPage: React.FC<Props> = () => {
  return (
    <div
      className={
        'bg-custom-black text-white w-[100vw] h-[100vh] flex flex-col items-center justify-center relative'
      }
    >
      <div className={'absolute top-10 left-10'}>
        <Logo />
      </div>
      <h1 className={'text-2xl'}>{_t('somethingWrong')}</h1>
    </div>
  )
}

export default ErrorPage

import { useMutation, UseMutationOptions } from 'react-query'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'

import { AxiosError } from 'axios'
import {
  TypeJoinFreeCommunityRequest,
  TypeJoinFreeResponse,
  TypePaymentCommunityRequest,
  TypePaymentCommunityResponse,
} from '@customTypes/productRequest'

/**********************************
 CREATE COMMUNITY PAYMENT
 **********************************/

type TypeCreateCommunityPaymentOptions = UseMutationOptions<
  TypePaymentCommunityResponse,
  AxiosError,
  TypePaymentCommunityRequest
>

const createCommunityPaymentRequest = async (
  values: TypePaymentCommunityRequest,
): Promise<TypePaymentCommunityResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.community_payment_request,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateCommunityPayment = (
  options?: TypeCreateCommunityPaymentOptions,
) => {
  return useMutation(createCommunityPaymentRequest, {
    ...options,
  })
}

/**********************************
 CREATE COMMUNITY JOIN FREE
 **********************************/

type TypeCreateCommunityJoinFreeOptions = UseMutationOptions<
  TypeJoinFreeResponse,
  Error,
  TypeJoinFreeCommunityRequest
>

const createCommunityJoinFreeRequest = async (
  values: TypeJoinFreeCommunityRequest,
): Promise<TypeJoinFreeResponse> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.community_join_free_request,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateCommunityJoinFree = (
  options?: TypeCreateCommunityJoinFreeOptions,
) => {
  return useMutation(createCommunityJoinFreeRequest, {
    ...options,
  })
}

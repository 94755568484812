import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { _t } from '@locales/index'
import { PaymentCouponStatusEnum } from '@enums/payment'
import { useCheckingCommunityCouponCode } from '@api/queries/payment'
import { COUPON_CODE_STYLE } from '@const/layout'
import { ReactComponent as CouponApprove } from '@assets/images/common/coupon_approve.svg'
import classNames from 'classnames'

interface Props {
  onValid: Function
  onInvalid: Function
  communityId: string
}

const CouponCodeInput: React.FC<Props> = ({
  onValid,
  onInvalid,
  communityId,
}) => {
  const [couponStatus, setCouponStatus] = useState<PaymentCouponStatusEnum>(
    PaymentCouponStatusEnum.Default,
  )

  const couponInput = useRef<HTMLInputElement>(null)

  const [code, setCode] = useState<string | undefined>(undefined)

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  const { data: coupon } = useCheckingCommunityCouponCode(
    code,
    communityId,
    timeZone,
    {
      enabled: Boolean(code),
      staleTime: 0,
    },
  )

  useEffect(() => {
    if (coupon === false) {
      setCouponStatus(PaymentCouponStatusEnum.Invalid)
      onInvalid()
    } else if (coupon?.id) {
      setCouponStatus(PaymentCouponStatusEnum.Approve)
      onValid(coupon)
    } else {
      setCouponStatus(PaymentCouponStatusEnum.Default)
      onInvalid()
    }
  }, [coupon, onInvalid, onValid])

  const handleCheckingCouponCode = useCallback(() => {
    const input = couponInput?.current?.value
    setCode(input?.trim())
  }, [])

  const handleClearCode = useCallback(() => {
    setCode('')
  }, [])

  const couponCodeDefaultButton = useMemo(() => {
    return (
      <div className={COUPON_CODE_STYLE.defaultContainer}>
        <input
          ref={couponInput}
          className={COUPON_CODE_STYLE.defaultInput}
          type='text'
          placeholder={_t('paymentCheckoutPromoCodePlaceholder')}
        />
        <button
          className={classNames(
            COUPON_CODE_STYLE.submitButton,
            'coupon-code-submit-btn',
          )}
          type='button'
          onClick={handleCheckingCouponCode}
        >
          <p className={COUPON_CODE_STYLE.defaultButtonText}> {_t('submit')}</p>
        </button>
      </div>
    )
  }, [handleCheckingCouponCode])

  const couponCodeInvalidButton = useMemo(() => {
    return (
      <div>
        <div className={COUPON_CODE_STYLE.invalidContainer}>
          <input
            readOnly={true}
            value={code}
            className={COUPON_CODE_STYLE.invalidInput}
            type='text'
            placeholder={_t('paymentCheckoutPromoCodePlaceholder')}
          />
          <button
            className={COUPON_CODE_STYLE.clearButton}
            type='button'
            onClick={handleClearCode}
          >
            <p className={COUPON_CODE_STYLE.defaultButtonText}>
              {' '}
              {_t('clear')}
            </p>
          </button>
        </div>
        <div className='h-4'></div>
        <div>
          <p className='text-sm font-poppins text-custom-red-2'>
            {_t('paymentCheckoutPromoCodeErrorMessage')}
          </p>
        </div>
      </div>
    )
  }, [code, handleClearCode])

  const couponCodeApproved = useMemo(() => {
    return (
      <div className={COUPON_CODE_STYLE.approved}>
        <CouponApprove />
      </div>
    )
  }, [])

  return (
    <div>
      {couponStatus === PaymentCouponStatusEnum.Approve && couponCodeApproved}
      {couponStatus === PaymentCouponStatusEnum.Default &&
        couponCodeDefaultButton}
      {couponStatus === PaymentCouponStatusEnum.Invalid &&
        couponCodeInvalidButton}
    </div>
  )
}

export default CouponCodeInput

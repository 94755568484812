export enum PaymentProviderTypeEnum {
  PayPal = 'PayPal',
  Stripe = 'Stripe',
  Free = 'Free',
}

export enum PaymentProcessEnum {
  Auto = 'AU',
  Manual = 'MA',
  Admin = 'AD',
}

export enum PaymentCouponStatusEnum {
  Default = 'DE',
  Invalid = 'IN',
  Approve = 'AP',
}

export enum PaymentCouponTypeEnum {
  Percentage = 'PE',
  FixedPrice = 'FP',
}

export enum PaymentDoneFormEnum {
  MemberEmail = 'MemberEmail',
  MemberPhone = 'MemberPhone',
}

export enum PaymentTncFormEnum {
  Email = 'Email',
}

export enum SmsPopupFormEnum {
  Phone = 'Phone',
}

import React from 'react'
import { TypeMemberRequest } from '@customTypes/memberRequest'

interface props {
  paymentRequest?: TypeMemberRequest
}

const FreeProviderCheckoutFooter = ({ paymentRequest }: props) => {
  return <div></div>
}

export default FreeProviderCheckoutFooter

import React, { Fragment } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { _t } from '@locales/index'
import UserMenu from '@containers/navbar/components/UserMenu'
import Logo from '@containers/navbar/components/Logo'
import { NAVBAR_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'

const Navbar = () => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const { user, isAuthenticated, loginWithPopup } = useAuth0()

  return (
    <div
      className='relative w-full flex items-center justify-center px-5 bg-grouper-blue flex-revers'
      style={{
        height: NAVBAR_HEIGHT[breakpoint],
      }}
    >
      <div className='z-50'>
        <Logo onClick={() => navigate('')} />
      </div>

      {/* WHEN LOGGED IN */}
      {isAuthenticated && (
        <Fragment>
          <div className='ml-auto'>
            <UserMenu name={user?.name || ''} imageUrl={user?.picture || ''} />
          </div>
        </Fragment>
      )}

      {/* NOT LOGGED IN */}
      {!isAuthenticated && (
        <p
          className='bg-grouper-aqua px-2 py-1 rounded font-sans text-black cursor-pointer capitalize ml-auto z-50'
          onClick={() => loginWithPopup({ display: 'popup' })}
        >
          {_t('logIn')}
        </p>
      )}
    </div>
  )
}

export default Navbar

import axios, { AxiosRequestConfig } from 'axios/index'
import camelcaseKeys from 'camelcase-keys'
import { TypeApiConfig, TypeApiKeyNames, TypeApiKeys } from '@customTypes/api'
import snakecaseKeys from 'snakecase-keys'
import { apiKeys } from '@api/apiKeys'
import { apiConfig } from '@api/apiConfig'
import { ResponseStatusCodeEnum } from '@enums/api'

class ObjectDoesNotExistError {
  response = {
    status: ResponseStatusCodeEnum.ObjectDoesNotExist,
  }
}

class ApiUtilsClass {
  apiConfig: TypeApiConfig = apiConfig as TypeApiConfig
  apiKeys: TypeApiKeys = apiKeys as TypeApiKeys

  constructor() {
    axios.defaults.withCredentials = true
    axios.defaults.headers.common['platform'] = 'web'
    axios.defaults.headers.common['app'] = 'grouper'
    axios.defaults.headers.common['version'] = '1'

    // convert camelcase to snakecase before sending a request
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      const newConfig = { ...config }
      if (config.params) {
        newConfig.params = snakecaseKeys(config.params)
      }
      if (config.data) {
        newConfig.data = snakecaseKeys(config.data)
      }
      return newConfig
    })

    // convert response data to camelcase
    axios.interceptors.response.use(
      response => {
        if (
          response &&
          response.status === ResponseStatusCodeEnum.ObjectDoesNotExist
        ) {
          const error = new ObjectDoesNotExistError()
          return Promise.reject(error)
        }
        if (response && response.data) {
          response.data = camelcaseKeys(response.data, { deep: true })
        }
        return response
      },
      function (error) {
        return Promise.reject(error)
      },
    )
  }

  public init = (config: TypeApiConfig, keys: TypeApiKeys) => {
    this.apiConfig = config
    this.apiKeys = keys
  }

  public setAccessToken = (accessToken: string) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }

  public get = (key: TypeApiKeyNames, args?: any) => {
    if (typeof args === 'string') {
      return axios.get(this.getUrlByKey(key) + args, {
        withCredentials: true,
      })
    } else {
      return axios.get(this.getUrlByKey(key), {
        data: args,
        withCredentials: true,
      })
    }
  }

  public post = (key: TypeApiKeyNames, args?: any) => {
    return axios.post(this.getUrlByKey(key), args)
  }

  public put = (key: TypeApiKeyNames, args?: any) => {
    return axios.put(this.getUrlByKey(key), args)
  }

  public putUrl = (key: TypeApiKeyNames, dynamicUrl: string, args?: any) => {
    return axios.put(this.getUrlByKey(key) + dynamicUrl, args)
  }

  public postUrl = (key: TypeApiKeyNames, dynamicUrl: string, args?: any) => {
    return axios.post(this.getUrlByKey(key) + dynamicUrl, args)
  }

  public delete = (key: TypeApiKeyNames, args?: any) => {
    return axios.delete(this.getUrlByKey(key), args)
  }

  public deleteUrl = (key: TypeApiKeyNames, dynamicUrl: string, args?: any) => {
    return axios.delete(this.getUrlByKey(key) + dynamicUrl, args)
  }

  private getUrlByKey = (key: TypeApiKeyNames) => {
    return this.apiConfig.apiUrl + this.apiKeys[key]
  }
}

const apiService = new ApiUtilsClass()

export default apiService

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useProtection } from '@hooks/auth/useProtection'
import {
  TypeMember,
  TypeMemberCreate,
  TypeMemberUpdate,
  TypeSendSubscriptionEmailRequest,
} from '@customTypes/member'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'

/**********************************
 GET MY MEMBER
 **********************************/

type TypeGetMyMemberOptions = UseQueryOptions<
  TypeMember,
  AxiosError,
  TypeMember,
  any
>

const getMyMember = async (): Promise<TypeMember> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.member)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetMyMember = (options?: TypeGetMyMemberOptions) => {
  const queryOptions = useProtection<TypeGetMyMemberOptions>(options)

  return useQuery(ServerStateKeysEnum.Member, getMyMember, {
    ...queryOptions,
  })
}

/**********************************
 CREATE MEMBER
 **********************************/

type TypeCreateMemberOptions = UseMutationOptions<
  TypeMember,
  AxiosError,
  TypeMemberCreate
>

const createMember = async (values: TypeMemberCreate): Promise<TypeMember> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.member, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useMemberCreate = (options?: TypeCreateMemberOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCreateMemberOptions>(options)

  return useMutation(createMember, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Member)
    },
    ...queryOptions,
  })
}

/**********************************
 UPDATE MEMBER
 **********************************/

type TypeUpdateMemberOptions = UseMutationOptions<
  string,
  AxiosError,
  TypeMemberUpdate
>

const updateMember = async (values: TypeMemberUpdate): Promise<string> => {
  try {
    const response = await apiService.put(ApiKeyNameEnum.member, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useMemberUpdate = (options?: TypeUpdateMemberOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeUpdateMemberOptions>(options)

  return useMutation(updateMember, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Member)
    },
    ...queryOptions,
  })
}

/**********************************
 SEND SUBSCRIPTION EMAIL
 **********************************/

type TypeSendSubscriptionEmailOption = UseMutationOptions<
  string,
  AxiosError,
  TypeSendSubscriptionEmailRequest
>

const sendSubscriptionEmail = async (
  values: TypeSendSubscriptionEmailRequest,
): Promise<string> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.member_subscribed_email,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useSendSubscriptionEmail = (
  options?: TypeSendSubscriptionEmailOption,
) => {
  const queryClient = useQueryClient()

  return useMutation(sendSubscriptionEmail, {
    onSuccess: () => {
      return queryClient.invalidateQueries(
        ServerStateKeysEnum.MemberSubscriptionEmail,
      )
    },
    ...options,
  })
}

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeAuthMemberState } from '@customTypes/store'

const initialState: TypeAuthMemberState = {
  token: '',
  currency: '',
}

/* CASE REDUCERS */
const set: CaseReducer<
  TypeAuthMemberState,
  PayloadAction<TypeAuthMemberState>
> = (state, action) => {
  state.token = action.payload.token
  state.currency = action.payload.currency
}

const clear: CaseReducer<TypeAuthMemberState> = () => initialState

/* SLICE */
const authMemberSlice = createSlice({
  name: 'memberRequestRedux',
  initialState,
  reducers: {
    set,
    clear,
  },
})

export const { set: setAuthMemberData, clear: clearAuthMemberData } =
  authMemberSlice.actions
const authMemberReducer = authMemberSlice.reducer

export default authMemberReducer

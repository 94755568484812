import * as EN from './en.json'
import { DE } from './de'
import * as RU from './ru.json'
import { PT } from './pt'
import * as HE from './he.json'
import * as ES from './es.json'
import * as AR from './ar.json'
import { LanguagesEnum } from '@enums/language'

const languages: any = {
  [LanguagesEnum.EN]: EN,
  [LanguagesEnum.DE]: DE,
  [LanguagesEnum.RU]: RU,
  [LanguagesEnum.ES]: ES,
  [LanguagesEnum.PT]: PT,
  [LanguagesEnum.HE]: HE,
  [LanguagesEnum.AR]: AR,
}

const DEFAULT_LANGUAGE = LanguagesEnum.EN

export type TypeLanguageKeys = keyof typeof EN

class LocaleInner {
  current: LanguagesEnum = '' as LanguagesEnum
  languageFile: any = null

  constructor() {
    this.setLocal()
  }

  public setLocal = () => {
    this.current = this.getLanguage()
    this.languageFile = languages[this.current] || languages[DEFAULT_LANGUAGE]
  }

  public getText = (key: TypeLanguageKeys) => {
    return this.languageFile[key] || languages[DEFAULT_LANGUAGE][key]
  }

  public setLanguage = (lang: LanguagesEnum) => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', lang || DEFAULT_LANGUAGE)
      window.location.reload()
    } else if (lang !== localStorage.getItem('language')) {
      localStorage.setItem('language', lang)
      window.location.reload()
    }
  }

  public getLanguage = (): LanguagesEnum => {
    const language = localStorage.getItem('language') || DEFAULT_LANGUAGE
    return language.slice(0, 2) as LanguagesEnum
  }
}

export const Locale = new LocaleInner()

export const _t = (
  key: TypeLanguageKeys,
  params?: Record<string, string | number | any>,
) => {
  let text = Locale.getText(key)
  params &&
    Object.keys(params).map((k: string): void => {
      let value: string | number = params[k]
      text = text.replaceAll('{' + k + '}', value)
      return text
    })
  return text
}

import React, { Fragment } from 'react'
import LogoWithText from '@assets/images/common/logo-with-text-white.svg'

interface Props {
  onClick?: () => void
}

const Logo: React.FC<Props> = ({ onClick }) => {
  return (
    <Fragment>
      <img
        src={LogoWithText}
        className='hidden sm:block h-8'
        alt='logo'
        onClick={onClick}
      />
      <img
        src={LogoWithText}
        className='block sm:hidden h-8'
        alt='logo'
        onClick={onClick}
      />
    </Fragment>
  )
}

export default Logo

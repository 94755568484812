import React from 'react'
import { _t } from '@locales/index'
import { ReactComponent as PayPalIcon } from '@assets/images/payment/paypal.svg'
import { ReactComponent as ArrowIcon } from '@assets/images/common/arrow.svg'

interface Props {
  onClickPayPal: () => void
}

const PaymentMethodPayPal: React.FC<Props> = ({ onClickPayPal }) => {
  return (
    <div>
      <p className='font-bold font-poppins text-lg capitalize'>
        {_t('paymentChangePaymentMethodPayPal')}
      </p>
      <div className='h-4' />
      <p className='font-poppins text-sm'>
        {_t('paymentChangePaymentMethodPayPalRedirect1')}
      </p>
      <p className='font-poppins text-sm'>
        {_t('paymentChangePaymentMethodPayPalRedirect2')}
      </p>
      <div className='h-8' />
      <div className='flex justify-center items-center'>
        <button
          className='
            px-6
            py-3
            w-[182px]
            button-shadow-1
            rounded
            '
          onClick={onClickPayPal}
        >
          <div className='flex-row'>
            <PayPalIcon className='inline mr-2' />
            <ArrowIcon fill={'#202020'} className='inline w-[16px] h-[16px]' />
          </div>
        </button>
      </div>
    </div>
  )
}

export default PaymentMethodPayPal

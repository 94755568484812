import React, { useMemo } from 'react'
import { _t } from '@locales/index'
import { ReactComponent as CreditCardsIcon } from '@assets/images/payment/credit-cards.svg'
import { CardElement } from '@stripe/react-stripe-js'

interface Props {
  onClickStripe: () => void
}

const PaymentMethodStripe: React.FC<Props> = ({ onClickStripe }) => {
  const cardStyle = useMemo(() => {
    return {
      style: {
        base: {
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#32325d',
          },
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    }
  }, [])

  return (
    <div>
      <div className='flex justify-start'>
        <CreditCardsIcon />
      </div>
      <div className='h-12' />
      <div>
        <CardElement
          id={'card-element'}
          options={cardStyle}
          className={'bg-custom-gray-6 py-4 px-6'}
        />
        <button
          className='btn-base bg-grouper-purple text-white mt-3 w-full shadow-none h-12 font-poppins text-sm'
          onClick={onClickStripe}
        >
          {_t('paymentChangePaymentMethodStripeButton')}
        </button>
      </div>
    </div>
  )
}

export default PaymentMethodStripe

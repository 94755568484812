import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import {
  TypeMemberRequest,
  TypeMemberRequestCreate,
} from '@customTypes/memberRequest'
import { useProtection } from '@hooks/auth/useProtection'

/**********************************
 CREATE MEMBER REQUEST
 **********************************/

type TypeCreateMemberRequestOptions = UseMutationOptions<
  TypeMemberRequest,
  AxiosError,
  TypeMemberRequestCreate
>

const createMemberRequest = async (
  values: TypeMemberRequestCreate,
): Promise<TypeMemberRequest> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.member_request,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useMemberRequestCreate = (
  options?: TypeCreateMemberRequestOptions,
) => {
  const queryOptions = useProtection<TypeCreateMemberRequestOptions>(options)

  return useMutation(createMemberRequest, {
    ...queryOptions,
  })
}

/**********************************
 GET PAYMENT LINK OPTION
 **********************************/

// Payment Link
type TypeGetPaymentLinkOptions = UseQueryOptions<
  TypeMemberRequest,
  AxiosError,
  TypeMemberRequest,
  any
>

const getPaymentLink = async (token: string): Promise<TypeMemberRequest> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.member_request, token)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetPaymentLink = (
  token: string,
  options?: TypeGetPaymentLinkOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.Payment, token],
    () => getPaymentLink(token),
    {
      ...options,
    },
  )
}

import { RouteObject } from 'react-router-dom'

import { ROUTES } from '@const/routes'
import { ProtectedComponent } from '@components/wrappers/protectedComponent/ProtectedComponent'

import Layout from '@containers/layout/Layout'
import NoMatch from '@containers/noMatch/NoMatch'
import Payment from '@containers/payment/Payment'
import Homepage from '@containers/homepage/Homepage'
import ContactUs from '@containers/contactUs/ContactUs'
import PaymentDone from '@containers/payment/pages/PaymentDone'
import MemberRequests from '@containers/memberRequests/MemberRequests'
import PaymentCheckout from '@containers/payment/pages/PaymentCheckout'
import ChangePaymentMethod from '@containers/payment/pages/ChangePaymentMethod'
import JoinCommunityFree from '@containers/memberRequests/pages/JoinCommunityFree'
import MemberRequestExpired from '@containers/memberRequests/pages/MemberRequestExpired'
import PaymentTermsAndConditions from '@containers/payment/pages/PaymentTermsAndConditions'
import UnsubscribeCommunity from '@containers/memberRequests/pages/UnsubscribeCommunity'
import PostUnsubscribeCommunity from '@containers/memberRequests/pages/PostUnsubscribeCommunity'
import PaymentRequestDone from '@containers/payment/pages/PaymentRequestDone'
import DeskTelegramLanding from '@containers/telegramPage/pages/DownloadTelegramOptions'
import ErrorPage from '@containers/telegramPage/pages/errorPage'

import TelegramPage from '@containers/telegramPage/TelegramPage'
import MobileLandingPage from '@containers/telegramPage/pages/mobileLanding'
import PaymentFreeProvider from '@containers/payment/pages/PaymentFreeProvider'
import MemberAuth from '@containers/createMemberLoader/AuthMember'
import CreateMemberLoader from '@containers/createMemberLoader/CreateMemberLoader'

export const AppRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: ROUTES.contact,
        element: <ProtectedComponent component={ContactUs} />,
      },
      {
        path: ROUTES.postUnsubscribeCommunity,
        element: <PostUnsubscribeCommunity />,
      },
      { path: ROUTES.noMatch, element: <NoMatch /> },
    ],
  },
  {
    path: ROUTES.payment,
    element: <Payment />,
    children: [
      {
        path: ROUTES.paymentTNC,
        element: <ProtectedComponent component={PaymentTermsAndConditions} />,
      },
      {
        path: ROUTES.paymentCheckout,
        element: <ProtectedComponent component={PaymentCheckout} />,
      },
      {
        path: ROUTES.paymentFreeProvider,
        element: <ProtectedComponent component={PaymentFreeProvider} />,
      },
      {
        path: ROUTES.paymentDone,
        element: <ProtectedComponent component={PaymentDone} />,
      },
      {
        path: ROUTES.paymentChangePaymentMethod,
        element: <ChangePaymentMethod />,
      },
      {
        path: ROUTES.paymentRequestDone,
        element: <PaymentRequestDone />,
      },
    ],
  },
  {
    path: ROUTES.memberRequest,
    element: <MemberRequests />,
    children: [
      {
        path: ROUTES.requestExpired,
        element: <MemberRequestExpired />,
      },
      {
        path: ROUTES.freeJoin,
        element: <ProtectedComponent component={JoinCommunityFree} />,
      },
      {
        path: ROUTES.unsubscribeCommunity,
        element: <UnsubscribeCommunity />,
      },
    ],
  },
  {
    path: ROUTES.memberAuth,
    element: <MemberAuth />,
  },
  {
    path: ROUTES.memberCreate,
    element: <ProtectedComponent component={CreateMemberLoader} />,
  },
  {
    path: ROUTES.telegramPage,
    element: <TelegramPage />,
    children: [
      {
        path: ROUTES.telegramDeskLanding,
        element: <DeskTelegramLanding />,
      },
      {
        path: ROUTES.telegramMobileLanding,
        element: <MobileLandingPage />,
      },
      {
        path: ROUTES.telegramErrorNoToken,
        element: <ErrorPage />,
      },
    ],
  },
]

import React, { useMemo } from 'react'
import { ErrorMessage, useField } from 'formik'
import classNames from 'classnames'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  id: string
  maxLength?: number
  wrapperClassName?: string // tailwind class
  fontClass?: string // tailwind class
  color?: string // tailwind class
  borderClass?: string // tailwind class
}

const EmailField: React.FC<Props> = ({
  className,
  wrapperClassName,
  fontClass,
  color,
  borderClass,
  ...props
}) => {
  const [field, meta] = useField(props)
  const error = useMemo(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <div className={`w-full ${wrapperClassName}`}>
      <input
        type={'email'}
        placeholder={props.placeholder}
        className={classNames(
          'w-full h-13 text-center focus:ring-0 focus:border-custom-gray-3 focus:text-custom-black outline-none placeholder:capitalize bg-transparent',
          className,
          fontClass,
          color,
          borderClass || 'border-1 rounded border-custom-gray-4',
          {
            '!border-red-500 focus:border-red-500 placeholder:text-red-500':
              error,
          },
        )}
        autoFocus={false}
        {...field}
        {...props}
      />
      <ErrorMessage
        name={props.name}
        component={'p'}
        className='text-red-500 mt-2'
      />
    </div>
  )
}

export default EmailField

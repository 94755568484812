import React from 'react'
import { _t } from '@locales/index'
import { ReactComponent as SecuredPaymentIcon } from '@assets/images/payment/secured-payment.svg'
import { DEFAULT_CURRENCY_SYMBOL, FREE_TRIAL_DAYS } from '@const/common'
import { TypeMemberRequest } from '@customTypes/memberRequest'

interface props {
  paymentRequest?: TypeMemberRequest
}

const PaymentCheckoutFooter = ({ paymentRequest }: props) => {
  return (
    <div>
      <div className='flex justify-center items-center gap-2.5'>
        <SecuredPaymentIcon />
        <p className='text-2xl font-bold font-raleway'>
          {_t('paymentCheckoutSecuredPayment')}
        </p>
      </div>
      <div className='h-12' />
      <p className='font-raleway text-custom-gray-3 text-xs first-letter:uppercase dynamic-align'>
        {_t('paymentCheckoutTerms')
          .replaceAll('{FREE_TRIAL_DAYS}', FREE_TRIAL_DAYS)
          .replaceAll(
            '{SUBSCRIPTION_PRICE}',
            paymentRequest?.memberPrice ||
              paymentRequest?.product?.price?.price,
          )
          .replaceAll(
            '{CURRENCY_SYMBOL}',
            paymentRequest?.memberCurrency?.symbol || DEFAULT_CURRENCY_SYMBOL,
          )}
      </p>
      <div className='h-4' />
    </div>
  )
}

export default PaymentCheckoutFooter

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import {
  TypeCommunity,
  TypeCommunityBase,
  TypeCommunityCreate,
  TypeCommunityUpdate,
  TypeGetCommunitiesResponse,
} from '@customTypes/community'
import { useProtection } from '@hooks/auth/useProtection'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'

/**********************************
 GET COMMUNITY BY ID
 **********************************/

type TypeGetCommunityOptions = UseQueryOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunity,
  any
>

const getCommunity = async (id: string | undefined): Promise<TypeCommunity> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.community, id)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetCommunity = (
  id: string | undefined,
  options?: TypeGetCommunityOptions,
) => {
  const queryOptions = useProtection<TypeGetCommunityOptions>(options)

  return useQuery([ServerStateKeysEnum.Community, id], () => getCommunity(id), {
    ...queryOptions,
  })
}

/**********************************
 GET COMMUNITY BY AFFILIATE TOKEN
 **********************************/

type TypeGetCommunityByAffiliateTokenOptions = UseQueryOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunity,
  any
>

const getCommunityByAffiliateToken = async (
  affiliateToken: string | undefined,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.get(
      ApiKeyNameEnum.community_affiliate_token,
      affiliateToken,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetCommunityByAffiliateToken = (
  affiliateToken: string | undefined,
  options?: TypeGetCommunityByAffiliateTokenOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.Community, 'affiliate_token'],
    () => getCommunityByAffiliateToken(affiliateToken),
    {
      ...options,
    },
  )
}

/**********************************
 GET ALL COMMUNITIES
 **********************************/

type TypeGetCommunitiesOptions = UseQueryOptions<
  TypeGetCommunitiesResponse,
  AxiosError,
  TypeCommunityBase[],
  any
>

const getCommunities = async (): Promise<TypeGetCommunitiesResponse> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.communities)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetCommunities = (options?: TypeGetCommunitiesOptions) => {
  const queryOptions = useProtection<TypeGetCommunitiesOptions>(options)

  return useQuery([ServerStateKeysEnum.Community, 'all'], getCommunities, {
    select: data => data.communities,
    ...queryOptions,
  })
}

/**********************************
 CREATE COMMUNITY
 **********************************/

type TypeCommunityCreateOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunityCreate
>

const createCommunity = async (
  values: TypeCommunityCreate,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.community, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityCreate = (options?: TypeCommunityCreateOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityCreateOptions>(options)

  return useMutation(createCommunity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}

/**********************************
 UPDATE COMMUNITY
 **********************************/

type TypeCommunityUpdateOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  TypeCommunityUpdate
>

const updateCommunity = async (
  values: TypeCommunityUpdate,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.put(ApiKeyNameEnum.community, values)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCommunityUpdate = (options?: TypeCommunityUpdateOptions) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityUpdateOptions>(options)

  return useMutation(updateCommunity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}

/**********************************
 PUBLISH COMMUNITY
 **********************************/
type TypeCommunityPublishOptions = UseMutationOptions<
  TypeCommunity,
  AxiosError,
  string // community id
>

const publishCommunity = async (
  communityId: string,
): Promise<TypeCommunity> => {
  try {
    const response = await apiService.post(ApiKeyNameEnum.publish_community, {
      communityId,
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export const usePublishCommunityMutation = (
  options?: TypeCommunityPublishOptions,
) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeCommunityPublishOptions>(options)

  return useMutation(publishCommunity, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.Community)
    },
    ...queryOptions,
  })
}

import React, { useCallback, useMemo, useState } from 'react'
import Skeleton from '@components/skeleton/Skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import UseLanguage from '@hooks/language/useLanguage'
import { _t } from '@locales/index'
import moment from 'moment/moment'
import FreeProviderCheckoutFooter from '@containers/payment/components/FreeProviderCheckoutFooter'
import { useCreateCommunityPayment } from '@api/queries/productRequest'
import { toast } from 'react-hot-toast'
import { TypePaymentCommunityRequest } from '@customTypes/productRequest'
import { PaymentProviderTypeEnum } from '@enums/payment'

const PaymentFreeProvider = () => {
  const { token } = useParams()
  const navigate = useNavigate()

  const { data: paymentRequest, isLoading: paymentRequestLoading } =
    useGetPaymentLink(token || '', {
      enabled: Boolean(token),
      onSuccess: memberRequest => {
        if (memberRequest?.language) {
          UseLanguage(memberRequest.language)
        }
      },
    })

  console.log(paymentRequest)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const createCommunityPayment = useCreateCommunityPayment({
    onError: error => {
      toast.error(error.message, { id: 'community-create-payment' })
    },
    onSettled: () => {
      setIsSubmitting(false)
    },
  })

  const handleOrder = useCallback(() => {
    setIsSubmitting(true)
    const values: TypePaymentCommunityRequest = {
      memberRequestId: token || '',
      providerName: PaymentProviderTypeEnum.Free,
    }

    createCommunityPayment.mutate(values, {
      onSuccess: res => {
        navigate(`/payment/done/${token}`)
      },
    })
  }, [createCommunityPayment, navigate, token])

  const paymentHeaderView = useMemo(() => {
    return (
      <div className='flex items-center gap-3'>
        <div className='min-w-[8px] bg-custom-black self-stretch' />
        <h1 className='title text-[32px]'>
          {_t('freeProviderCheckoutTitle', {
            COMMUNITY_NAME: paymentRequest?.product?.name,
          })}
        </h1>
      </div>
    )
  }, [paymentRequest])

  const priceView = useMemo(() => {
    return (
      <div className='flex justify-between border-x-0 border-y border-custom-gray-4 py-5'>
        <div>
          <p className='font-poppins font-lg font-bold'>
            {_t('paymentCheckoutPrice')}
          </p>
        </div>
        <div className={'flex flex-col self-end items-end'}>
          <p className='font-poppins font-lg font-bold text-custom-green-1'>
            {_t('paymentCheckoutFree')}
          </p>
        </div>
      </div>
    )
  }, [])

  const launchDateView = useMemo(() => {
    if (!paymentRequest?.launchDate?.date) {
      return null
    }
    return (
      <div className='flex justify-between border-b border-custom-gray-4 py-5'>
        <p className='font-poppins text-lg font-bold'>
          <span className='text-2xl mr-2'>&#127881;</span> {_t('launchDate')}
        </p>
        <p className='text-lg capitalize'>
          {moment.utc(paymentRequest?.launchDate?.date).format('MMM DD, YYYY')}
        </p>
      </div>
    )
  }, [paymentRequest?.launchDate?.date])

  const paymentFooterView = useMemo(() => {
    return (
      <div>
        <FreeProviderCheckoutFooter paymentRequest={paymentRequest} />
      </div>
    )
  }, [paymentRequest])

  return (
    <div className='page px-5 py-7.5'>
      {paymentRequestLoading && (
        <div className='flex flex-col gap-5'>
          <Skeleton className='!h-36' count={5} />
        </div>
      )}
      {paymentRequest && (
        <div>
          {paymentHeaderView}
          <div className='h-8' />
          {priceView}
          {launchDateView}
          <div className='h-8' />

          <button
            className='btn-base btn-yellow-contained btn-free-member-joined w-full shadow-none h-12 font-poppins text-sm complete-order-btn'
            onClick={handleOrder}
            disabled={isSubmitting}
          >
            {_t('freeProviderCheckoutCompleteOrder')}
          </button>

          <div className='h-12' />
          {paymentFooterView}
        </div>
      )}
    </div>
  )
}

export default PaymentFreeProvider

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeDrawerMenuType } from '@customTypes/general'
import { TypeDrawerMenuState } from '@customTypes/store'

const initialState: TypeDrawerMenuState = {
  type: null,
}

/* CASE REDUCERS */
const open: CaseReducer<
  TypeDrawerMenuState,
  PayloadAction<TypeDrawerMenuType>
> = (state, action) => {
  state.type = action.payload
}

const close: CaseReducer<TypeDrawerMenuState> = state => {
  state.type = null
}

/* SLICE */
const drawerMenuSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    open,
    close,
  },
})

export const { open: openDrawerMenu, close: closeDrawerMenu } =
  drawerMenuSlice.actions
const drawerMenuReducer = drawerMenuSlice.reducer

export default drawerMenuReducer

import React from 'react'
import { _t } from '@locales/index'
import { ReactComponent as StripeIcon } from '@assets/images/payment/stripe.svg'
import { DEFAULT_CURRENCY_SYMBOL, FREE_TRIAL_DAYS } from '@const/common'
import { TypeMemberRequest } from '@customTypes/memberRequest'

interface props {
  paymentRequest?: TypeMemberRequest
}

const JoinFreeFooter = ({ paymentRequest }: props) => {
  return (
    <div>
      <div className='h-12' />
      <p className='font-raleway text-custom-gray-3 text-xs first-letter:uppercase'>
        {_t('paymentCheckoutTerms')
          .replaceAll('{FREE_TRIAL_DAYS}', FREE_TRIAL_DAYS)
          .replaceAll(
            '{SUBSCRIPTION_PRICE}',
            paymentRequest?.memberPrice ||
              paymentRequest?.product?.price?.price,
          )
          .replaceAll(
            '{CURRENCY_SYMBOL}',
            paymentRequest?.memberCurrency?.symbol || DEFAULT_CURRENCY_SYMBOL,
          )}
      </p>
      <div className='h-4' />
      <div className='flex flex-col items-center'>
        <p className='font-raleway font-xs'>{_t('paymentCheckoutPoweredBy')}</p>
        <div className='h-1' />
        <StripeIcon />
      </div>
    </div>
  )
}

export default JoinFreeFooter

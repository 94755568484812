import {
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useMutation,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import apiService from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { TypeMemberLink } from '@customTypes/member'
import { useProtection } from '../../hooks/auth/useProtection'

// Payment Link
type TypeGetMemberLinkOptions = UseQueryOptions<
  TypeMemberLink,
  AxiosError,
  TypeMemberLink,
  any
>

const getMemberLink = async (token: string): Promise<TypeMemberLink> => {
  try {
    const response = await apiService.get(ApiKeyNameEnum.member_link, token)
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetMemberLink = (
  token: string,
  options?: TypeGetMemberLinkOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.MemberLink, token],
    () => getMemberLink(token),
    {
      ...options,
    },
  )
}

/**********************************
 SEND MEMBER LINK VIA SMS
 **********************************/

type TypeSendMemberLinkViaSmsOptions = UseMutationOptions<any, AxiosError>

const sendMemberLinkViaSms = async (): Promise<string> => {
  try {
    const response = await apiService.post(
      ApiKeyNameEnum.send_member_link_via_sms,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useSendMemberLinkViaSms = (
  options?: TypeSendMemberLinkViaSmsOptions,
) => {
  const queryClient = useQueryClient()

  const queryOptions = useProtection<TypeSendMemberLinkViaSmsOptions>(options)

  return useMutation(sendMemberLinkViaSms, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ServerStateKeysEnum.MemberLink)
    },
    ...queryOptions,
  })
}

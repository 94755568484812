import React from 'react'
import Loader from '@components/loader/Loader'

const Homepage = () => {
  return (
    <div className='page px-5 bg-grouper-blue items-center justify-center'>
      <Loader visible={true} fullWidth={true} />
    </div>
  )
}

export default Homepage

import React from 'react'
import { _t } from '@locales/index'

const SubscribeTitle = () => {
  return (
    <div className='flex items-center gap-3'>
      <div className='min-w-[3px] bg-custom-yellow self-stretch' />
      <div>
        <h1 className={'text-2xl text-white'}>
          {_t('postUnsubscribeCommunityHeader1')}
        </h1>
        <h1 className={'text-2xl text-white'}>
          {_t('postUnsubscribeCommunityHeader2')}
        </h1>
      </div>
    </div>
  )
}

export default SubscribeTitle

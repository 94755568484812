export enum ServerStateKeysEnum {
  Profile = 'profile',
  Community = 'community',
  AccessToken = 'accessToken',
  Media = 'media',
  Member = 'member',
  MemberSubscriptionEmail = 'memberSubscriptionEmail',
  TemplateGuidelines = 'templateGuidelines',
  Payment = 'payment',
  PayoutSettings = 'payoutSettings',
  Coupons = 'coupons',
  UserAgreement = 'userAgreement',
  MemberLink = 'memberLink',
}

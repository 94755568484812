import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { useCreateStripeSetupIntent } from '@api/queries/payment'
import Skeleton from '@components/skeleton/Skeleton'
import { ReactComponent as CreditCardsIcon } from '@assets/images/payment/credit-cards.svg'
import { ReactComponent as PayPalIcon } from '@assets/images/payment/paypal.svg'
import PaymentMethod from '@containers/payment/components/PaymentMethod'
import {
  TypeCommunityCouponCode,
  TypePaymentMethod,
  TypeStripeSetupIntentRequest,
} from '@customTypes/payment'
import { toast } from 'react-hot-toast'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentCouponTypeEnum, PaymentProviderTypeEnum } from '@enums/payment'
import { useNavigate, useParams } from 'react-router-dom'
import PaymentCheckoutFooter from '@containers/payment/components/CheckoutFooter'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { ReactComponent as StripeIcon } from '@assets/images/payment/stripe.svg'
import {
  StripeCardElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js/types/stripe-js/elements'
import { isNil } from 'ramda'
import CouponCodeInput from '@containers/payment/components/CouponCodeInput'
import { useCreateCommunityPayment } from '@api/queries/productRequest'
import { TypePaymentCommunityRequest } from '@customTypes/productRequest'
import moment from 'moment'
import { useGetMyMember } from '@api/queries/member'
import UseLanguage from '@hooks/language/useLanguage'
import Loader from '@components/loader/Loader'
import { OPEN_TELEGRAM_APP_LINK_BASE } from '@const/common'
import { ErrorTypeEnum } from '@enums/errorPoup'
import { useDispatch } from 'react-redux'
import { closeErrorPopup, openErrorPopup } from '@reducers/errorPopup'

const PaymentCheckoutInner = () => {
  const [discount, setDiscount] = useState(0)
  const { token } = useParams()
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)
  const [isSetLanguage, seIsSetLanguage] = useState(false)

  const dispatch = useDispatch()
  const cardStyle = useMemo(() => {
    return {
      style: {
        base: {
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#32325d',
          },
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    }
  }, [])

  const stripe = useStripe()
  const elements = useElements()

  const { data: myMember } = useGetMyMember()

  const [paymentMethod, setPaymentMethod] = useState<TypePaymentMethod>(
    PaymentProviderTypeEnum.Stripe,
  )

  const [coupon, setCoupon] = useState<TypeCommunityCouponCode>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { data: paymentRequest } = useGetPaymentLink(token || '', {
    enabled: Boolean(token),
    onSuccess: memberRequest => {
      // Choose the current payment method
      if (memberRequest?.language) {
        UseLanguage(memberRequest.language)
      }
    },
  })

  useEffect(() => {
    seIsSetLanguage(true)
  }, [paymentRequest?.language])

  const createCommunityPayment = useCreateCommunityPayment({
    onError: error => {
      toast.error(error.message, { id: 'community-create-payment' })
    },
    onSettled: () => {
      setIsSubmitting(false)
    },
  })

  const createStripeSetupIntent = useCreateStripeSetupIntent({
    onError: error => {
      toast.error(error.message, { id: 'create-stripe-setup-intent' })
    },
    onSettled: () => {
      setIsSubmitting(false)
    },
  })

  const handlePaymentMethod = useCallback((method: TypePaymentMethod) => {
    setPaymentMethod(method)
  }, [])

  const handlePayPal = useCallback(() => {
    const values: TypePaymentCommunityRequest = {
      memberRequestId: token || '',
      providerName: PaymentProviderTypeEnum.PayPal,
      couponId: coupon?.id || undefined,
    }

    createCommunityPayment.mutate(values, {
      onSuccess: res => {
        const paypalLink = res.url
        window.location.href = paypalLink || ''
      },
    })
  }, [coupon, createCommunityPayment, token])

  const openTelegramApp = useCallback(() => {
    window.open(OPEN_TELEGRAM_APP_LINK_BASE)
    dispatch(closeErrorPopup())
  }, [dispatch])

  const errorHandling = useCallback(
    (errType: ErrorTypeEnum) => {
      switch (errType) {
        case ErrorTypeEnum.PaymentCardError:
          dispatch(
            openErrorPopup({
              visible: true,
              title: _t('errorWhoops'),
              description: _t('paymentFailMemberAlreadyInTheCommunity'),
              actionButtonLabel: _t('checkItOut'),
              onSubmit: openTelegramApp,
              type: 1,
            }),
          )
          break
        case ErrorTypeEnum.MemberAlreadyInTheCommunity:
          dispatch(
            openErrorPopup({
              visible: true,
              title: _t('errorWhoops'),
              description: _t('paymentValidationError'),
              actionButtonLabel: _t('tryAgain'),
              onSubmit: () => dispatch(closeErrorPopup()),
              type: 1,
            }),
          )
          break
      }
    },
    [dispatch, openTelegramApp],
  )

  const handleStripe = useCallback(async () => {
    const setupIntentValues: TypeStripeSetupIntentRequest = {
      memberId: paymentRequest?.member.id || '',
      email: paymentRequest?.member?.email || myMember?.email || '',
    }

    createStripeSetupIntent.mutate(setupIntentValues, {
      onSuccess: async createdSetupIntent => {
        if (!elements) return

        let cardElement: StripeCardNumberElement | StripeCardElement | null =
          elements.getElement('cardNumber')

        if (isNil(cardElement)) {
          cardElement = elements.getElement('card')
        }

        if (!cardElement) return

        await stripe
          ?.confirmCardSetup(createdSetupIntent.clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })
          .then(res => {
            if (res.error) {
              toast.error(res.error.message || 'Your card was declined!', {
                id: 'create-stripe-setup-intent',
              })
              setLoad(false)
              errorHandling(ErrorTypeEnum.MemberAlreadyInTheCommunity)
              return
            }

            const values: TypePaymentCommunityRequest = {
              memberRequestId: token || '',
              providerName: PaymentProviderTypeEnum.Stripe,
              setupIntentId: createdSetupIntent.setupIntentId,
              couponId: coupon?.id || undefined,
            }

            createCommunityPayment.mutate(values, {
              onSuccess: () => {
                navigate(`/payment/done/${token}`)
              },
              onError: () => {
                setLoad(false)
                errorHandling(ErrorTypeEnum.PaymentCardError)
              },
            })
          })
          .catch(() => {
            toast.error('System error, contact support', {
              id: 'create-stripe-setup-intent',
            })
          })
      },
    })
  }, [
    paymentRequest?.member.id,
    paymentRequest?.member?.email,
    myMember?.email,
    createStripeSetupIntent,
    elements,
    stripe,
    token,
    coupon?.id,
    createCommunityPayment,
    errorHandling,
    navigate,
  ])

  const handleOrder = useCallback(() => {
    setLoad(true)
    setIsSubmitting(true)
    switch (paymentMethod) {
      case PaymentProviderTypeEnum.PayPal:
        return handlePayPal()
      case PaymentProviderTypeEnum.Stripe:
        return handleStripe()
    }
  }, [handlePayPal, handleStripe, paymentMethod])

  /**********************************
   COUPON LOGIC
   **********************************/

  const handleCouponValid = useCallback(
    (validCouponCode: TypeCommunityCouponCode) => {
      setCoupon(validCouponCode)
    },
    [],
  )

  const handleCouponInvalid = useCallback(() => {
    setCoupon(undefined)
  }, [])

  const calculateDiscountAmount = useCallback(() => {
    if (coupon?.type === PaymentCouponTypeEnum.Percentage) {
      const fullPrice = paymentRequest?.memberPrice || 0
      const dc = coupon?.discount || 0

      const discountAmount = (fullPrice * dc) / 100
      setDiscount(dc)
      if (dc === 100) {
        return `-${paymentRequest?.memberCurrency?.symbol}${discountAmount}`
      } else {
        return `-${paymentRequest?.memberCurrency?.symbol}${discountAmount} (${dc}%)`
      }
    }

    return ''
  }, [coupon, paymentRequest])

  const calculateDiscountPrice = useCallback(() => {
    if (coupon?.type === PaymentCouponTypeEnum.Percentage) {
      const fullPrice = paymentRequest?.memberPrice || 0
      const dc = coupon?.discount || 0
      const discountAmount = (fullPrice * dc) / 100
      const discountPrice = fullPrice - discountAmount
      const roundedPrice =
        Math.round((discountPrice + Number.EPSILON) * 100) / 100

      if (dc === 100) {
        return `${_t('paymentCheckoutFree')}`
      } else {
        return `${paymentRequest?.memberCurrency?.symbol}${roundedPrice} / ${_t(
          'paymentCheckoutMonth',
        )}`
      }
    }

    return ''
  }, [coupon, paymentRequest])

  /**********************************
   PRICE VIEWS
   **********************************/

  const priceWithCouponView = useMemo(() => {
    return (
      <div className={'flex flex-col border-x-0 border-y border-custom-gray-4'}>
        <div className='flex justify-between  py-5'>
          <div>
            <p className='font-poppins font-lg font-bold'>
              {_t('paymentCheckoutPrice')}
              <span className='uppercase text-xs'>{` (${paymentRequest?.memberCurrency?.iso})`}</span>
            </p>
          </div>
          <div className={'flex flex-col self-end items-end'}>
            <p className='font-poppins font-lg font-bold line-through'>
              {`${paymentRequest?.memberCurrency?.symbol}${
                paymentRequest?.memberPrice
              } / ${_t('paymentCheckoutMonth')}`}
            </p>
            <div className='h-2' />

            <p className='font-poppins font-lg font-bold text-custom-green-2'>
              {calculateDiscountPrice()}
            </p>

            <p className='font-poppins font-lg text-custom-green-2'>
              {calculateDiscountAmount()}
            </p>
            <div className='h-2' />
          </div>
        </div>
        <div className={'flex flex-col items-start'}>
          <p className='font-poppins text-sm capitalize text-custom-pink'>
            ({discount + _t('paymentCheckoutCouponValidForMonth')})
          </p>
          <p className='font-poppins text-lg text-custom-pink font-bold'>
            {_t('paymentCheckoutFreeTrial')}
          </p>
          <p className='font-poppins text-lg text-custom-pink font-bold'>
            {_t('paymentCheckoutCancelAnyTime')}
          </p>
        </div>
        <div className='h-2' />
      </div>
    )
  }, [
    calculateDiscountAmount,
    calculateDiscountPrice,
    discount,
    paymentRequest,
  ])

  const priceView = useMemo(() => {
    return (
      <div className='flex flex-col gap-2 justify-between border-x-0 border-y border-grouper-blue py-5'>
        <div className={'flex items-start justify-between'}>
          <div className={'flex flex-col items-start'}>
            <p className='font-poppins font-lg font-bold flex items-center gap-2'>
              {_t('paymentCheckoutPrice')}
              <span className='uppercase text-xs'>{` (${paymentRequest?.memberCurrency?.symbol})`}</span>
            </p>
            <p className='font-poppins text-lg text-custom-pink font-bold'>
              {_t('paymentCheckoutFreeTrial')}
            </p>
          </div>
          <div className={'flex flex-col items-end'}>
            <p className='font-poppins font-lg font-bold'>
              {`${paymentRequest?.memberCurrency?.symbol}${
                paymentRequest?.memberPrice
              } / ${_t('paymentCheckoutMonth')}`}
            </p>
            <p className='h-2'>{_t('paymentCheckoutCancelAnyTime')}</p>
          </div>
        </div>
      </div>
    )
  }, [paymentRequest])

  const launchDateView = useMemo(() => {
    if (!paymentRequest?.launchDate?.date) {
      return null
    }
    return (
      <div className='flex justify-between border-b border-custom-gray-4 py-5'>
        <p className='font-poppins text-lg font-bold'>
          <span className='text-2xl mr-2'>&#127881;</span> {_t('launchDate')}
        </p>
        <p className='text-lg capitalize'>
          {moment.utc(paymentRequest?.launchDate?.date).format('MMM DD, YYYY')}
        </p>
      </div>
    )
  }, [paymentRequest?.launchDate?.date])

  /**********************************
   PAYMENT METHOD VIEW
   **********************************/

  const paymentMethodView = useMemo(() => {
    return (
      <div>
        <div className='h-8' />
        <p className='font-bold font-poppins text-lg'>
          {_t('paymentCheckoutPaymentMethod')}
        </p>
        <div className='h-8' />
        <div className='flex justify-between'>
          <PaymentMethod
            type={PaymentProviderTypeEnum.Stripe}
            icon={<CreditCardsIcon />}
            selected={paymentMethod === PaymentProviderTypeEnum.Stripe}
            handleSelected={handlePaymentMethod}
          />
          <PaymentMethod
            type={PaymentProviderTypeEnum.PayPal}
            icon={<PayPalIcon />}
            selected={paymentMethod === PaymentProviderTypeEnum.PayPal}
            handleSelected={handlePaymentMethod}
          />
        </div>
        <div className='h-12' />
        {paymentMethod === PaymentProviderTypeEnum.Stripe && (
          <div style={{ direction: 'ltr' }}>
            <CardElement
              id={'card-element'}
              options={cardStyle}
              className={'bg-custom-gray-6 py-4 px-6 '}
            />
          </div>
        )}
      </div>
    )
  }, [cardStyle, handlePaymentMethod, paymentMethod])

  /**********************************
   PAYMENT HEADER VIEW
   **********************************/

  const paymentHeaderView = useMemo(() => {
    return (
      <div className='flex items-center gap-3'>
        <div className='min-w-[8px] bg-grouper-blue self-stretch rounded-full' />
        <h1 className='title-lowercase text-[32px]'>
          {_t('paymentCheckoutTitle', {
            CREATOR_NAME: paymentRequest?.owner?.name,
          })}
        </h1>
      </div>
    )
  }, [paymentRequest])

  /**********************************
   COUPON CODE VIEW
   **********************************/

  const couponCodeView = useMemo(() => {
    return (
      <div>
        <p className='font-poppins font-lg font-bold'>
          {_t('paymentCheckoutPromoCode')}
        </p>
        <div className='h-4' />
        <CouponCodeInput
          onValid={handleCouponValid}
          onInvalid={handleCouponInvalid}
          communityId={paymentRequest?.product?.communityId || ''}
        />
      </div>
    )
  }, [
    handleCouponInvalid,
    handleCouponValid,
    paymentRequest?.product?.communityId,
  ])

  /**********************************
   PAYMENT FOOTER VIEW
   **********************************/

  const paymentFooterView = useMemo(() => {
    return (
      <div>
        <PaymentCheckoutFooter paymentRequest={paymentRequest} />

        <div>
          {paymentMethod === PaymentProviderTypeEnum.Stripe && (
            <div className='flex flex-col items-center'>
              <p className='font-raleway font-xs'>
                {_t('paymentCheckoutPoweredBy')}
              </p>
              <StripeIcon />
            </div>
          )}
        </div>
      </div>
    )
  }, [paymentMethod, paymentRequest])

  return (
    <div className='page px-5 py-7.5'>
      <Loader visible={!isSetLanguage || load} fullWidth={true} />
      {!isSetLanguage && (
        <div className='flex flex-col gap-5'>
          <Skeleton className='!h-36 transition-all' count={5} />
        </div>
      )}
      {paymentRequest && (
        <div className={'transition-all'}>
          {paymentHeaderView}
          <div className='h-8' />
          {!coupon && priceView}
          {launchDateView}
          {coupon && priceWithCouponView}
          <div className='h-6' />
          {couponCodeView}
          {paymentMethodView}
          <button
            className='btn-base-no-capitalize mt-3 bg-grouper-purple text-white w-full shadow-none h-12 font-poppins text-sm checkout-button'
            onClick={handleOrder}
            disabled={isSubmitting}
          >
            {_t('paymentCheckoutCompleteOrder')}
          </button>

          <div className='h-12' />
          {paymentFooterView}
        </div>
      )}
    </div>
  )
}

export default PaymentCheckoutInner

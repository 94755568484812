import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { NAVBAR_HEIGHT } from '@const/layout'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'

const Payment = () => {
  const breakpoint = useBreakpoint()

  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  return (
    <div
      className='
        flex
        flex-col
        items-center
        overflow-hidden
        w-full
        h-[calc(var(--vh,_1vh)_*_100)]
        sm:h-screen
      '
    >
      <div
        className='flex-1 flex w-full md:max-w-2xl'
        style={{
          height: `calc(${
            isSmallerDevice ? 'var(--vh, 1vh) * 100' : '100%'
          } - ${NAVBAR_HEIGHT[breakpoint]}px)`,
        }}
      >
        <Outlet />
      </div>
    </div>
  )
}

export default Payment

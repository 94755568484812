import { LanguagesDirection, LanguagesEnum } from '@enums/language'
import { LanguagesSettings } from '@const/languages'
import { Locale } from '@locales/index'

const UseLanguage = (language: LanguagesEnum | null) => {
  language = language as LanguagesEnum
  if (language && LanguagesSettings[language]) {
    Locale.setLanguage(language)
    if (LanguagesSettings[language].direction === LanguagesDirection.RTL) {
      document.documentElement.style.setProperty('--direction-page', 'rtl')
      document.documentElement.style.setProperty('--flex-row', 'row-reverse')
      document.documentElement.style.setProperty('--flex-item', 'flex-start')
      document.documentElement.style.setProperty('--text-item', 'right')
    }
  }
  return null
}

export default UseLanguage

import React from 'react'
import { _t } from '@locales/index'
import { ReactComponent as BotIcon } from '@assets/images/common/bot-happy.svg'
import { telegramPageUtils } from '@containers/telegramPage/utils'

interface Props {
  domain: string
  start: string
}

const CommunityPageDesk: React.FC<Props> = ({ start, domain }) => {
  return (
    <div
      className={
        'flex flex-col items-center justify-center w-[476px] mt-[40px]'
      }
    >
      <h1 className={'text-[24px] text-center'}>{_t('afterDownloadTgDesk')}</h1>
      <div className={'flex justify-between mt-[40px] mb-10'}>
        <button
          className={
            'w-[290px] h-[56px] bg-white rounded-full text-custom-black text-[18px] p-2'
          }
          onClick={() =>
            window.open(
              telegramPageUtils.generateOpenMemberBotLink(start, domain),
              '_self',
            )
          }
        >
          <div className={'flex items-center justify-center relative'}>
            <BotIcon className='w-10 h-10 absolute left-0' />
            <p className={'absolute right-3'}>{_t('openCommunityBot')}</p>
          </div>
        </button>
      </div>
      <h1>( {_t('recommendMobileApp')} )</h1>
    </div>
  )
}

export default CommunityPageDesk

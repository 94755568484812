import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ChangePaymentMethodInner from '@containers/payment/pages/ChangePaymentMethodInner'
import { STRIPE_PUBLIC_KEY } from '@services/stripe/stripeConfig'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const ChangePaymentMethod = () => {
  return (
    <Elements stripe={stripePromise}>
      <ChangePaymentMethodInner />
    </Elements>
  )
}

export default ChangePaymentMethod

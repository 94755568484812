import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetMyMember, useMemberCreate } from '@api/queries/member'
import { toast } from 'react-hot-toast'
import { ResponseStatusCodeEnum } from '@enums/api'
import { useMemberRequestCreate } from '@api/queries/memberRequest'
import { ROUTES } from '@const/routes'
import { MemberRequestTypeEnum } from '@enums/memberRequest'
import Loader from '@components/loader/Loader'

const CreateMemberLoader = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = localStorage.getItem('token')
  const currency = localStorage.getItem('currency')
  const product_price_id = localStorage.getItem('product_price_id')

  const { mutate: createMember } = useMemberCreate({
    onError: error => {
      toast.error(error.message, {
        id: 'creating-member',
      })
    },
  })

  const { mutate: createMemberRequest } = useMemberRequestCreate({
    onError: error => {
      toast.error(error.message, {
        id: 'creating-member-request',
      })
    },
  })

  const {
    data: member,
    error: memberError,
    refetch: memberRefetch,
  } = useGetMyMember()

  // create member if member object does not exist
  useEffect(() => {
    if (
      !member &&
      memberError &&
      memberError.response?.status === ResponseStatusCodeEnum.ObjectDoesNotExist
    ) {
      if (!token || !currency) return
      createMember(
        {
          affiliateToken: token,
          currency: currency,
        },
        {
          onSuccess: async () => await memberRefetch(),
        },
      )
    }
  }, [
    createMember,
    currency,
    member,
    memberError,
    memberRefetch,
    searchParams,
    token,
  ])

  // create member request if member exists
  useEffect(() => {
    if (!member) return
    if (!token || !currency) return
    createMemberRequest(
      {
        memberId: member.id,
        affiliateToken: token,
        requestType: MemberRequestTypeEnum.Payment,
        currency: currency,
        productPriceId: product_price_id || '',
      },
      {
        onSuccess: data => {
          navigate(ROUTES.memberRequest.replace(':token', data.id))
        },
      },
    )
  }, [
    createMemberRequest,
    currency,
    member,
    navigate,
    product_price_id,
    searchParams,
    token,
  ])

  return <Loader visible={true} fullWidth={true} />
}

export default CreateMemberLoader

import React from 'react'
import ReactDOM from 'react-dom'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createBrowserHistory } from 'history'
import { auth0Config } from '@services/auth0/auth0Config'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { store } from '@store/store'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// creating our own history object to be accessed by auth0 outside the router wrapper
export const history = createBrowserHistory({ window })

// callback to take the user back to the protected route after log in/sign up
const onRedirectCallback = (appState?: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname)

  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  // @ts-ignore
  window.dataLayer.push({
    event: 'MEMBER_SIGNUP',
  })
}

// set up the query client for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30 * 1000,
    },
  },
})

const container = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={auth0Config['domain']}
        clientId={auth0Config['clientId']}
        redirectUri={window.location.origin}
        audience={auth0Config['audience']}
        scope='read:current_user update:current_user_metadata'
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation='localstorage'
        authorizationParams={{
          display: 'popup',
        }}
      >
        <HistoryRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </HistoryRouter>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  container,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { useCallback, useMemo, useState } from 'react'
import useBreakpoint from '@hooks/responsive/useBreakpoint'
import { BreakpointsEnum } from '@enums/theme'
import { NAVBAR_HEIGHT } from '@const/layout'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { MemberRequestTypeEnum } from '@enums/memberRequest'
import UseLanguage from '@hooks/language/useLanguage'
import { useCreateCommunityPayment } from '@api/queries/productRequest'
import { toast } from 'react-hot-toast'
import { TypePaymentCommunityRequest } from '@customTypes/productRequest'
import { PaymentProviderTypeEnum } from '@enums/payment'
import Loader from '@components/loader/Loader'

const MemberRequests = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()

  const isSmallerDevice: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  const [isLoading, setIsLoading] = useState(false)

  const createCommunityPayment = useCreateCommunityPayment({
    onError: error => {
      toast.error(error.message, {
        id: 'community-create-payment',
        duration: 3000,
      })
    },
  })

  const handleFreeProviderMemberRequest = useCallback(() => {
    setIsLoading(true)
    const values: TypePaymentCommunityRequest = {
      memberRequestId: token || '',
      providerName: PaymentProviderTypeEnum.Free,
    }
    createCommunityPayment.mutate(values, {
      onSuccess: () => {
        return navigate(`/payment/done/${token}`)
      },
      onError: () => {
        return navigate(`/`)
      },
    })
  }, [createCommunityPayment, navigate, token])

  useGetPaymentLink(token || '', {
    enabled: Boolean(token),
    onSuccess: memberRequest => {
      localStorage.removeItem('token')
      localStorage.removeItem('currency')

      if (memberRequest?.language) {
        UseLanguage(memberRequest?.language)
      }

      switch (memberRequest?.type) {
        case MemberRequestTypeEnum.Payment:
          return navigate(`/payment/checkout/${token}`)
        case MemberRequestTypeEnum.Free_Provider:
          return handleFreeProviderMemberRequest()
        case MemberRequestTypeEnum.Join_For_Free:
          return navigate(`/member_request/${token}/free_join`)
        case MemberRequestTypeEnum.Change_Payment_Method:
          return navigate(`/payment/change_payment_method/${token}`)
        case MemberRequestTypeEnum.Unsubscribe:
          return navigate(`/member_request/${token}/unsubscribe`)
      }
    },
    onError: () => {
      navigate(`/member_request/${token}/request_expired`)
    },
  })

  return (
    <div
      className='       
        flex
        flex-col
        items-center
        overflow-hidden
        w-full
        h-[calc(var(--vh,_1vh)_*_100)]
        sm:h-screen'
    >
      {isLoading && <Loader visible={true} fullWidth />}
      {!isLoading && (
        <div
          className='flex-1 flex w-full md:max-w-2xl'
          style={{
            height: `calc(${
              isSmallerDevice ? 'var(--vh, 1vh) * 100' : '100%'
            } - ${NAVBAR_HEIGHT[breakpoint]}px)`,
          }}
        >
          <Outlet />
        </div>
      )}
    </div>
  )
}

export default MemberRequests

import {
  TypeCommunityMedia,
  TypeProfileMedia,
  TypeProfileMediaType,
} from '@customTypes/media'
import { CommunityMediaTypeEnum } from '@enums/media'

export const getProfileMediaByType = (
  type: TypeProfileMediaType,
  media: TypeProfileMedia[],
): TypeProfileMedia[] | undefined =>
  media ? media.filter(mediaItem => mediaItem.subType === type) : undefined

export const getCommunityMediaByType = (
  type: CommunityMediaTypeEnum,
  media: TypeCommunityMedia[],
): TypeCommunityMedia[] | undefined =>
  media ? media.filter(mediaItem => mediaItem.subType === type) : undefined

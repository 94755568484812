import React from 'react'
import { TypePaymentMethod } from '@customTypes/payment'
import classNames from 'classnames'

interface Props {
  type: TypePaymentMethod
  icon: any
  selected?: boolean
  handleSelected: Function
}

const PaymentMethod: React.FC<Props> = ({
  type,
  icon,
  handleSelected,
  selected,
}) => {
  return (
    <div
      className={classNames(
        'btn-base justify-center items-center h-20 w-40 rounded',
        selected
          ? 'border-2 border-grouper-blue shadow-[0px_4px_8px_rgba(0,0,0,0.2)]'
          : 'border border-custom-gray-5',
      )}
      onClick={() => {
        handleSelected(type)
      }}
    >
      {icon}
    </div>
  )
}

export default PaymentMethod

import { useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { useParams } from 'react-router-dom'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { ReactComponent as TelegramIcon } from '@assets/images/common/telegram-icon-invert.svg'
import { TELEGRAM_APP_LINK } from '@const/common'
import { ReactComponent as LogoWithText } from '@assets/images/common/logo-with-text-white.svg'
import { ProfileMediaTypeEnum } from '@root/src/core/enums/media'
import { getProfileMediaByType } from '../../profile/utils'
import LogoNoText from '@assets/images/common/logo-white.svg'
import Avatar from '@root/src/components/avatar/Avatar'
import SmsPopup from '../components/SmsPopup'
import UpdateDetailsPopup from '../components/UpdateDetailsPopup'

const PaymentDone = () => {
  const { token } = useParams()

  const [showUpdateDetailsPopup, setUpdateDetailsPopup] = useState(false)

  const { data: paymentRequest, refetch: paymentRequestRefetch } =
    useGetPaymentLink(token || '', {
      enabled: Boolean(token),
      retry: 1,
    })

  const ownerProfileImageUrl = useMemo<string | undefined>(() => {
    if (paymentRequest?.owner?.media) {
      const media = getProfileMediaByType(
        ProfileMediaTypeEnum.Profile,
        paymentRequest.owner.media,
      )
      if (media) {
        return media?.[0]?.url || LogoNoText
      }
    } else return LogoNoText
  }, [paymentRequest])

  const nonRussianPage = useMemo(() => {
    return (
      <div>
        {paymentRequest && (
          <div>
            <SmsPopup
              onClose={() => paymentRequestRefetch()}
              paymentRequest={paymentRequest}
            />
            <UpdateDetailsPopup
              onClose={() => setUpdateDetailsPopup(false)}
              paymentRequest={paymentRequest}
              isOpen={showUpdateDetailsPopup}
            />

            <LogoWithText className='mx-auto mb-4' />
            <Avatar
              widthClass='mx-auto h-16 w-16'
              imageUrl={ownerProfileImageUrl}
            />
            <h1 className='pt-4 h4 text-center text-white'>
              {_t('paymentDone_Header')}
            </h1>
            <h1 className='h4 text-center text-white'>
              {paymentRequest.product?.name}?
            </h1>
            <div className='my-12 border-[#828282] border-b-[1px]' />

            <ol className='list-decimal space-y-10 pl-5'>
              <li className='body2 text-white'>
                <p>
                  {_t('paymentDone_Step_1')}{' '}
                  <a href={TELEGRAM_APP_LINK} className='text-[#1E88D3]'>
                    {_t('paymentDone_Step_1_Download')}
                  </a>
                </p>

                <a
                  href={paymentRequest?.member?.link?.replace(
                    'GrouperBuddyBot',
                    paymentRequest?.member?.userBot || 'GrouperBuddyBot',
                  )}
                  className='
                    flex 
                    items-center 
                    justify-center 
                    mt-6
                    p-3
                    rounded-full 
                    bg-grouper-purple
                  '
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <p className='body4 font-bold mx-2 text-white'>
                    {_t('paymentDone_Access_Link')}
                  </p>
                </a>
              </li>

              <li className='body2 text-white'>
                <p>
                  {_t('paymentDone_Step_2')}{' '}
                  <a href={TELEGRAM_APP_LINK} className='text-[#1E88D3]'>
                    {_t('paymentDone_Step_2_Download')}
                  </a>
                </p>
                <a
                  href={TELEGRAM_APP_LINK}
                  className='
                    flex 
                    items-center 
                    justify-center 
                    mt-8 
                    p-3 
                    rounded-full 
                    bg-gradient-to-b from-[#34B0DF] to-[#1E88D3]
                  '
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <span>
                    <TelegramIcon width={24} height={24} />
                  </span>
                  <span className='font-inter mx-2 text-white'>
                    {_t('downloadTelegram')}
                  </span>
                </a>
              </li>
            </ol>

            <div className='my-12 border-[#828282] border-b-[1px]' />

            <p className='body2 text-white'>
              {_t('paymentDone_UpdateDetails')}{' '}
              <span
                onClick={() => setUpdateDetailsPopup(true)}
                className='text-grouper-aqua'
              >
                {_t('paymentDone_ClickHere')}
              </span>
            </p>
          </div>
        )}
      </div>
    )
  }, [
    ownerProfileImageUrl,
    paymentRequest,
    paymentRequestRefetch,
    showUpdateDetailsPopup,
  ])

  const russianPage = useMemo(() => {
    return (
      <div>
        {paymentRequest && (
          <div>
            <LogoWithText className='mx-auto mb-6' />
            <Avatar
              widthClass='mx-auto h-16 w-16'
              imageUrl={ownerProfileImageUrl}
            />
            <h1 className='mt-8 h3 text-center text-white'>
              {paymentRequest.product?.name}
            </h1>

            <h2 className='mt-4 h5 text-center text-white'>
              {_t('paymentDoneRu_Header1')}
            </h2>
            <h2 className='h5 text-center text-white'>
              {_t('paymentDoneRu_Header2')}
            </h2>

            <p className='mt-12 text-white text-center'>
              {_t('paymentDoneRu_Header3')}
            </p>

            <a
              href={paymentRequest?.member?.link?.replace(
                'GrouperBuddyBot',
                paymentRequest?.member?.userBot || 'GrouperBuddyBot',
              )}
              className='
                    flex 
                    items-center 
                    justify-center 
                    mt-6
                    p-4 
                    rounded-full 
                    bg-gradient-to-b from-[#34B0DF] to-[#A079ED]
                  '
              target='_blank'
              rel='noreferrer noopener'
            >
              <p className='body1 mx-2 text-white'>
                {_t('paymentDoneRu_Button')}
              </p>
            </a>
          </div>
        )}
      </div>
    )
  }, [ownerProfileImageUrl, paymentRequest])

  return (
    <div className='page px-5 bg-grouper-blue'>
      {paymentRequest?.language === 'ru' ? russianPage : nonRussianPage}
    </div>
  )
}

export default PaymentDone

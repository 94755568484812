import React, { useCallback, useState } from 'react'
import { ReactComponent as LeftArrow } from '@assets/images/common/left_arrow.svg'
import { ReactComponent as Done } from '@assets/images/common/done.svg'
import { _t } from '@locales/index'
import { useSendContactUsInfo } from '@api/queries/contactUs'
import { TypeSendContactUsRequest } from '@customTypes/contactUs'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { contactUsValidationSchema } from '@containers/contactUs/utils'
import ContactUsFormSubject from '@containers/contactUs/components/ContactUsFormSubject'
import ContactUsFormBody from '@containers/contactUs/components/ContactUsFormBody'
import { useGetMyMember } from '@api/queries/member'
import { HelpCenterLink } from '@const/common'

const ContactUs = () => {
  const navigate = useNavigate()
  const [isNoteSent, setIsNoteSent] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const sendContactUsInfo = useSendContactUsInfo()
  const { data: member } = useGetMyMember()

  const handleSendInfo = useCallback(
    (values: TypeSendContactUsRequest) => {
      setDisabled(true)
      let sendInfoObj: TypeSendContactUsRequest = {
        subject: values.subject,
        body: values.body,
      }
      if (values.subject === '') {
        sendInfoObj = {
          subject: member?.firstName + ' ' + member?.lastName,
          body: values.body,
        }
      }
      sendContactUsInfo.mutate(sendInfoObj, {
        onSuccess: () => {
          setIsNoteSent(true)
          setDisabled(false)
        },
        onError: () => {
          setDisabled(false)
        },
      })
    },
    [sendContactUsInfo, member?.firstName, member?.lastName],
  )

  const handleOnChange = useCallback(() => {
    setIsNoteSent(false)
    setDisabled(false)
  }, [setDisabled, setIsNoteSent])

  return (
    <div className='pt-12 px-12 page relative'>
      <div className='w-6 h-6 mb-4' onClick={() => navigate(-1)}>
        <LeftArrow fill={'#202020'} width={24} height={24} />
      </div>
      <div>
        <h1 className='font-poppins font-bold sm:text-5xl text-4xl text-custom-gray-1'>
          {_t('contactUsTitle')}
        </h1>
        <p className='mt-1.5 font-poppins font-normal text-lg text-custom-gray-2'>
          {_t('contactUsSubtitle')}
        </p>
      </div>
      <Formik
        initialValues={{ subject: '', body: '' }}
        onSubmit={handleSendInfo}
        validationSchema={contactUsValidationSchema}
      >
        <Form onChange={handleOnChange}>
          <div className={'max-w-xl flex justify-end flex-col items-end mt-10'}>
            <ContactUsFormSubject name={'subject'} />
            <ContactUsFormBody
              name={'body'}
              onError={() => {
                console.log('onError')
              }}
            />
            <div
              className={
                'flex flex-row-reverse items-center justify-between w-full'
              }
            >
              <div className={'w-32  flex items-center justify-center'}>
                {!isNoteSent ? (
                  <button
                    className='btn-base bg-custom-black text-white px-10 py-2.5 text-xs font-normal w-32'
                    type='submit'
                    disabled={disabled}
                  >
                    {_t('contactUsSend')}
                  </button>
                ) : (
                  <button
                    className='btn-base bg-custom-black text-white px-10 py-2.5 text-xs font-normal w-32'
                    type='button'
                    disabled={disabled}
                  >
                    <div className={'pr-1.5'}>
                      <Done fill={'#14D365'} width={'16px'} />
                    </div>
                    {_t('contactUsSent')}
                  </button>
                )}
              </div>
              <div className={'flex items-center justify-center'}>
                <button
                  className='bg-white text-custom-black w-36 sm:w-48 h-9 border border-custom-gray-1 text-[13px] sm:px-10 sm:py-2.5 sm:text-xs font-normal rounded'
                  type='button'
                  onClick={() => window.open(HelpCenterLink)}
                >
                  {_t('contactUsHelpCenterButton')}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default ContactUs

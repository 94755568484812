import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentCheckoutInner from '@containers/payment/pages/PaymentCheckoutInner'
import { STRIPE_PUBLIC_KEY } from '@services/stripe/stripeConfig'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const PaymentCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentCheckoutInner />
    </Elements>
  )
}

export default PaymentCheckout

import { BreakpointsEnum } from '@enums/theme'

export const NAVBAR_HEIGHT = {
  [BreakpointsEnum.sm]: 64,
  [BreakpointsEnum.md]: 64,
  [BreakpointsEnum.lg]: 64,
  [BreakpointsEnum.xl]: 64,
  [BreakpointsEnum.dxl]: 64,
}

export const COUPON_CODE_STYLE = {
  defaultContainer: [
    'flex',
    'w-full',
    'h-11',
    'rounded',
    'border-transparent',
    'text-center',
    'bg-custom-gray-6',
  ].join(' '),
  defaultInput: [
    'flex-auto',
    'w-60',
    'rounded-l',
    'border-nones',
    'border-transparent',
    'text-center',
    'bg-custom-gray-6',
    'placeholder:text-center',
    'placeholder:text-black',
    'placeholder:font-poppins',
    'placeholder:font-normal',
    'placeholder:text-sm',
    'focus:ring-0',
    'focus:border-black',
    'focus:placeholder:text-transparent',
  ].join(' '),
  submitButton: [
    'flex-auto',
    'w-24',
    'rounded-r',
    'border-transparent',
    'bg-grouper-blue',
  ].join(' '),
  defaultButtonText: [
    'font-poppins',
    'text-sm',
    'font-bold',
    'text-white',
    'tracking-[0.02em]',
    'text-center',
  ].join(' '),
  approved: [
    'flex',
    'justify-center',
    'items-center',
    'w-full',
    'h-11',
    'rounded',
    'border-transparent',
    'bg-custom-green-2',
  ].join(' '),
  invalidContainer: [
    'flex',
    'w-full',
    'h-11',
    'rounded',
    'border-transparent',
    'text-center',
    'bg-custom-gray-6',
  ].join(' '),
  invalidInput: [
    'flex-auto',
    'w-60',
    'rounded-l',
    'border-nones',
    'text-custom-red-2',
    'text-center',
    'border-custom-red-2',
    'bg-custom-red-2/10',
    'placeholder:text-center',
    'placeholder:text-custom-red-2',
    'placeholder:font-poppins',
    'placeholder:font-normal',
    'placeholder:text-sm',
    'focus:ring-0',
    'focus:border-custom-red-2',
    'focus:placeholder:text-transparent',
  ].join(' '),
  clearButton: ['flex-auto', 'w-24', 'rounded-r', 'bg-custom-black'].join(' '),
}

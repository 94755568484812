import React from 'react'
import Avatar from '@components/avatar/Avatar'
import DropdownMenu from '@components/dropdownMenu/DropdownMenu'
import DropdownMenuItem from '@components/dropdownMenu/DropdownMenuItem'
import { _t } from '@locales/index'
import { useAuth0 } from '@auth0/auth0-react'
import { DropdownItemVariantEnum } from '@enums/theme'

interface Props {
  imageUrl?: string
  name?: string
}

const UserMenu: React.FC<Props> = ({ imageUrl, name = '' }) => {
  const { logout } = useAuth0()

  return (
    <DropdownMenu
      triggerComponent={<Avatar imageUrl={imageUrl} name={name} />}
      zIndex={12}
    >
      <DropdownMenuItem
        text={_t('signOutOfApp')}
        onClick={() => logout({ returnTo: window.location.origin })}
        variant={DropdownItemVariantEnum.Inverted}
      />
    </DropdownMenu>
  )
}

export default UserMenu

import React, { useCallback } from 'react'
import Modal from '@components/modal/Modal'
import { useNavigate } from 'react-router-dom'
import { _t } from '@locales/index'
import { TypeErrorPopupState } from '@customTypes/store'
import { closeErrorPopup } from '@reducers/errorPopup'
import { useDispatch } from 'react-redux'

type Props = TypeErrorPopupState

const ErrorPopUp: React.FC<Props> = ({
  visible,
  title,
  description,
  actionButtonLabel,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const openLink = useCallback(() => {
    navigate('/contact-us')
    dispatch(closeErrorPopup())
  }, [dispatch, navigate])
  return (
    <Modal open={visible}>
      <div
        className={
          'w-[350px] md:w-[539px] h-72 p-8 flex flex-col justify-between errorPopup'
        }
      >
        <h1 className={'text-2xl text-custom-red-1'}>{title}</h1>
        <p className={'text-xl'}>{description}</p>
        <div>
          <button
            className={'bg-custom-pink text-white rounded p-3 m-2'}
            type='button'
            onClick={onSubmit}
          >
            {actionButtonLabel}
          </button>
          <button className={''} type='button' onClick={openLink}>
            {_t('contactUsTitle')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ErrorPopUp

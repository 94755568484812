import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import { startsWith } from 'ramda'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string // tailwind class
  fontClass?: string // tailwind class
  color?: string // tailwind class
  borderClass?: string // tailwind class
  inputStyle?: Object
}

const MobilePhoneInput: React.FC<Props> = ({
  id,
  className,
  wrapperClassName,
  fontClass,
  color,
  borderClass,
  inputStyle,
  placeholder,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props.name || '')

  return (
    <div className={classNames('w-full ltr', className)}>
      <PhoneInput
        {...props}
        {...field}
        value={field.value}
        disableDropdown={true}
        country={'us'}
        placeholder={'(201) 5550123'}
        onChange={value => {
          helpers.setValue(value)
        }}
        inputProps={{
          id: id,
        }}
        isValid={(inputNumber, c, countries) => {
          return countries.some((country: any) => {
            return (
              startsWith(inputNumber, country.dialCode) ||
              startsWith(country.dialCode, inputNumber)
            )
          })
        }}
        containerStyle={{
          direction: 'ltr',
        }}
        buttonStyle={{
          direction: 'ltr',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
        inputStyle={{
          direction: 'ltr',
          width: '100%',
          margin: 0,
          paddingBottom: 24,
          paddingTop: 24,
          ...inputStyle,
        }}
      />
      <ErrorMessage
        name={props.name || ''}
        component={'p'}
        className='text-red-500 mt-2'
      />
    </div>
  )
}

export default MobilePhoneInput

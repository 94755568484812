import React from 'react'

const NoMatch = () => {
  return (
    <div className='page justify-center items-center'>
      <p className='text-2xl font-bold text-center'>
        This route doesn't exist yet. But we're working on it
      </p>
    </div>
  )
}

export default NoMatch

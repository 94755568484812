import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTES } from '@const/routes'
import { telegramPageUtils } from '@containers/telegramPage/utils'
import { BreakpointsEnum } from '@enums/theme'
import useBreakpoint from '@hooks/responsive/useBreakpoint'

const TelegramPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isTelegramOpen, setIsTelegramOpen] = useState<boolean>(false)
  const breakpoint = useBreakpoint()
  const isMobile: boolean = useMemo(
    () => breakpoint === BreakpointsEnum.sm,
    [breakpoint],
  )

  useEffect(() => {
    const domain = searchParams.get('domain')
    const start = searchParams.get('start')

    if (!start || !domain) {
      return navigate(ROUTES.telegramErrorNoToken)
    }

    // Try open telegram App
    if (isMobile) {
      window.open(
        telegramPageUtils.generateOpenMemberBotLink(start, domain),
        '_self',
      )
      document.onvisibilitychange = function () {
        if (document.visibilityState === 'hidden') {
          setIsTelegramOpen(true)
        }
      }
      setTimeout(() => {
        if (isMobile && !isTelegramOpen) {
          navigate(
            ROUTES.telegramMobileLanding +
              '?' +
              telegramPageUtils.generateLinkQueryParams(start, domain),
          )
        }
      }, 2 * 1000)
    } else {
      navigate(
        ROUTES.telegramDeskLanding +
          '?' +
          telegramPageUtils.generateLinkQueryParams(start, domain),
      )
    }
  }, [isMobile, isTelegramOpen, searchParams, navigate])

  return <Outlet />
}

export default TelegramPage

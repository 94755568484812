import React, { useCallback, useMemo } from 'react'
import Skeleton from '@components/skeleton/Skeleton'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { _t } from '@locales/index'
import UseLanguage from '@hooks/language/useLanguage'
import { ReactComponent as Arrow } from '@assets/images/common/yellow_arrow.svg'
import UnsubscribeTitle from '@containers/memberRequests/components/UnsubscribedTitle'
import SubscribeTitle from '@containers/memberRequests/components/SubscribedTitle'
import classNames from 'classnames'

const PostUnsubscribeCommunity = () => {
  const { token } = useParams()
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error')

  const { data: paymentRequest, isLoading: paymentLinkLoading } =
    useGetPaymentLink(token || '', {
      enabled: Boolean(token),
      onSuccess: memberRequest => {
        // Choose the current payment method
        if (memberRequest?.language) {
          UseLanguage(memberRequest.language)
        }
      },
    })

  const handleRedirectToCommunities = useCallback(() => {
    window.location.href = 'https://wearegrouper.com/'
  }, [])

  const isError = useMemo(() => error === 'True', [error])

  return (
    <div
      className={classNames(
        'page pt-13 sm:pt-26 px-5 sm:items-center',
        isError ? 'bg-white' : 'bg-custom-black',
      )}
    >
      {paymentLinkLoading && (
        <div className='flex flex-col gap-5'>
          <Skeleton className='!h-36' count={5} />
        </div>
      )}
      {paymentRequest && (
        <div>
          {isError ? <UnsubscribeTitle /> : <SubscribeTitle />}

          <div className='h-16' />

          <div className='flex'>
            <div className='flex flex-col justify-items-end mr-5'>
              <h1
                className={classNames(
                  'text-left font-poppins text-lg',
                  isError ? 'text-custom-black' : 'text-white',
                )}
              >
                {_t('postUnsubscribeCommunitySubHeader1')}
              </h1>
              <h1
                className={classNames(
                  'text-left font-poppins text-lg',
                  isError ? 'text-custom-black' : 'text-white',
                )}
              >
                {_t('postUnsubscribeCommunitySubHeader2')}
              </h1>
            </div>
            <Arrow onClick={handleRedirectToCommunities} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PostUnsubscribeCommunity

export enum ApiKeyNameEnum {
  // profile
  profile = 'profile',
  my_profile = 'my_profile',
  media = 'media',

  // community
  community = 'community',
  communities = 'communities',
  publish_community = 'publish_community',
  community_affiliate_token = 'affiliate_token',

  // members
  member = 'member',
  my_member = 'my_member',
  members_count = 'members_count',
  community_members = 'community_members',
  member_subscribed_email = 'subscribed_email',

  // media
  user_media = 'user_media',
  media_create = 'media_create',
  media_pre_upload = 'media_pre_upload',

  // interests
  main_interests = 'interests',
  parent_interests = 'parent_interests',
  related_interests = 'related_interests',
  search_interests = 'search_interests',
  profile_interests = 'profile_interests',
  community_interests = 'community_interests',

  // locations
  search_locations = 'search_locations',

  // guidelines
  template_guidelines = 'template_guidelines',
  community_guidelines = 'community_guidelines',

  //paypal_payment
  create_paypal_payment = 'create_paypal_payment',
  delete_paypal = 'delete_paypal',
  connect_paypal = 'connect_paypal',

  // payment
  community_unsubscribe_request = 'community_unsubscribe_request',
  change_payment_method = 'change_payment_method',
  stripe_setup_intent = 'stripe_setup_intent',
  payment_details = 'payment_details',
  available_funds = 'available_funds',
  billing_transactions = 'billing_transactions',

  // product request
  community_payment_request = 'community_payment_request',
  community_join_free_request = 'community_join_free_request',
  community_coupon_code_check = 'community_coupon_code_check',

  // payout
  payout_settings = 'payout_settings',

  // member requests
  member_request = 'member_request',

  // member link
  member_link = 'member_link',
  send_member_link_via_sms = 'send_member_link_via_sms',

  // contact_us
  contact_us = 'contact_us',

  // coupons
  create_coupon = 'create_coupon',
  delete_coupon = 'delete_coupon',
  get_coupon_list = 'get_coupon_list',
  validate_coupon_code = 'validate_coupon_code',

  // user
  change_password = 'change_password',

  // launch date
  launch_date = 'launch_date',

  //user agreement
  user_agreement = 'user_agreement',
  user_agreement_for_member = 'user_agreement_for_member',
}

export enum ResponseStatusCodeEnum {
  ObjectDoesNotExist = 204,
}

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeErrorPopupState } from '@customTypes/store'
import { ErrorTypeEnum } from '@enums/errorPoup'

const initialState: TypeErrorPopupState = {
  type: ErrorTypeEnum.MemberAlreadyInTheCommunity,
  visible: false,
  onSubmit: () => {},
  title: '',
  description: '',
  actionButtonLabel: '',
}

/* CASE REDUCERS */
const open: CaseReducer<
  TypeErrorPopupState,
  PayloadAction<TypeErrorPopupState>
> = (state, action) => {
  state.type = action.payload.type
  state.visible = action.payload.visible
  state.onSubmit = action.payload.onSubmit
  state.title = action.payload.title
  state.description = action.payload.description
  state.actionButtonLabel = action.payload.actionButtonLabel
}

const close: CaseReducer<TypeErrorPopupState> = state => {
  return initialState
}

/* SLICE */
const errorPopupSlice = createSlice({
  name: 'errorPopupModal',
  initialState,
  reducers: {
    open,
    close,
  },
})

export const { open: openErrorPopup, close: closeErrorPopup } =
  errorPopupSlice.actions
const errorPopupReducer = errorPopupSlice.reducer

export default errorPopupReducer

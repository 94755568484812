import { EnvironmentEnum } from '@enums/environment'
import { env } from '@src/env'
import { TypeStripeConfig } from '@customTypes/stripe'

const localConfig: TypeStripeConfig = {
  publicKey:
    'pk_test_51KfqzNDHIbemrNHw2eqmCbggnHmDoZZKHcb1Mfk8Y1RRUT6ycWeGsT30uVdZ78S92qxISCqbd0xdvmj7e8KYU1rL00QTI66S09',
}

const developmentConfig: TypeStripeConfig = {
  publicKey:
    'pk_test_51KfqzNDHIbemrNHw2eqmCbggnHmDoZZKHcb1Mfk8Y1RRUT6ycWeGsT30uVdZ78S92qxISCqbd0xdvmj7e8KYU1rL00QTI66S09',
}

const stagingConfig: TypeStripeConfig = {
  publicKey:
    'pk_test_51KfqzNDHIbemrNHw2eqmCbggnHmDoZZKHcb1Mfk8Y1RRUT6ycWeGsT30uVdZ78S92qxISCqbd0xdvmj7e8KYU1rL00QTI66S09',
}

const productionConfig: TypeStripeConfig = {
  publicKey:
    'pk_live_51KfqzNDHIbemrNHwsUc37asLyFCVdEImlAs3wQXVH0fNilfcn5SwYwiDuvbv4rs13EXf2M0nm9tMHN4xOoRzwKYZ00TDuOfwMP',
}

const config = {
  [EnvironmentEnum.Local]: localConfig,
  [EnvironmentEnum.Development]: developmentConfig,
  [EnvironmentEnum.Staging]: stagingConfig,
  [EnvironmentEnum.Production]: productionConfig,
}

export const stripeConfig = config[env]

export const STRIPE_PUBLIC_KEY = stripeConfig.publicKey

import React, { useCallback, useMemo } from 'react'
import { _t } from '@locales/index'
import {
  useChangePaymentMethod,
  useCreateStripeSetupIntent,
} from '@api/queries/payment'
import Skeleton from '@components/skeleton/Skeleton'

import {
  TypeChangePaymentMethodRequest,
  TypeStripeSetupIntentRequest,
} from '@customTypes/payment'
import { toast } from 'react-hot-toast'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useNavigate, useParams } from 'react-router-dom'
import PaymentCheckoutFooter from '@containers/payment/components/CheckoutFooter'
import { useGetPaymentLink } from '@api/queries/memberRequest'
import { ReactComponent as StripeIcon } from '@assets/images/payment/stripe.svg'
import { PaymentProviderTypeEnum } from '@enums/payment'
import {
  StripeCardElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js/types/stripe-js/elements'
import { isNil } from 'ramda'
import PaymentMethodStripe from '@containers/payment/components/PaymentMethodStripe'
import { ROUTES } from '@const/routes'
import UseLanguage from '@hooks/language/useLanguage'
import PaymentMethodPayPal from '@containers/payment/components/PaymentMethodPayPal'

const ChangePaymentMethodInner = () => {
  const stripe = useStripe()
  const { token } = useParams()
  const elements = useElements()
  const navigate = useNavigate()

  const { data: paymentRequest, isLoading: paymentLinkLoading } =
    useGetPaymentLink(token || '', {
      enabled: Boolean(token),
      onSuccess: memberRequest => {
        // Choose the current payment method
        if (memberRequest?.language) {
          UseLanguage(memberRequest.language)
        }
      },
    })

  const createStripeSetupIntent = useCreateStripeSetupIntent({
    onError: error => {
      toast.error(error.message, { id: 'create-stripe-setup-intent' })
    },
  })

  const createChangePaymentMethod = useChangePaymentMethod({
    onError: error => {
      toast.error(error.message, { id: 'change-payment-method' })
    },
    onSuccess: () => {
      navigate(ROUTES.paymentRequestDone.replace(':token', token || ''))
    },
  })

  const handleStripeChangePaymentMethod = useCallback(async () => {
    const setupIntentValues: TypeStripeSetupIntentRequest = {
      memberId: paymentRequest?.member.id || '',
    }

    createStripeSetupIntent.mutate(setupIntentValues, {
      onSuccess: async createdSetupIntent => {
        if (!elements) return

        let cardElement: StripeCardNumberElement | StripeCardElement | null =
          elements.getElement('cardNumber')

        if (isNil(cardElement)) {
          cardElement = elements.getElement('card')
        }

        if (cardElement) {
          await stripe?.confirmCardSetup(createdSetupIntent.clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })

          const values: TypeChangePaymentMethodRequest = {
            memberId: paymentRequest?.member?.id || '',
            setupIntentId: createdSetupIntent.setupIntentId,
            subscriptionId: paymentRequest?.params?.subscriptionId || '',
          }

          createChangePaymentMethod.mutate(values)
        }
      },
    })
  }, [
    createChangePaymentMethod,
    createStripeSetupIntent,
    elements,
    paymentRequest,
    stripe,
  ])

  const handlePayPalChangePaymentMethod = useCallback(() => {
    window.location.href = 'https://www.paypal.com/'
  }, [])

  const changePaymentHeader = useMemo(() => {
    return (
      <div className={'flex gap-3 dynamic-align dynamic-flex'}>
        <div className='min-w-[8px] bg-grouper-blue self-stretch rounded-full' />
        <h1 className='font-poppins text-3xl sm:text-4xl font-bold text-[32px]'>
          {_t('paymentChangePaymentMethodHeader')}
        </h1>
      </div>
    )
  }, [])

  const stripeView = useMemo(() => {
    return (
      <div>
        <PaymentMethodStripe onClickStripe={handleStripeChangePaymentMethod} />
        <div className='h-8' />
        <PaymentCheckoutFooter paymentRequest={paymentRequest} />
        <div className='flex flex-col items-center'>
          <p className='font-raleway font-xs'>
            {_t('paymentCheckoutPoweredBy')}
          </p>
          <StripeIcon />
        </div>
      </div>
    )
  }, [handleStripeChangePaymentMethod, paymentRequest])

  const paypalView = useMemo(() => {
    return (
      <div>
        <PaymentMethodPayPal onClickPayPal={handlePayPalChangePaymentMethod} />
        <div className='h-8' />
        <PaymentCheckoutFooter paymentRequest={paymentRequest} />
      </div>
    )
  }, [handlePayPalChangePaymentMethod, paymentRequest])

  const freeView = useMemo(() => {
    return (
      <div>
        <div className='flex justify-between border-x-0 border-y border-custom-gray-4 py-5 dynamic-align'>
          <div>
            <p className='font-poppins font-lg font-bold'>
              {_t('paymentCheckoutPrice')}
            </p>
          </div>
          <div className={'flex flex-col self-end items-end'}>
            <p className='font-poppins font-lg font-bold text-custom-green-1'>
              {_t('paymentCheckoutFree')}
            </p>
          </div>
        </div>
        <div className='h-8' />
        <p>{_t('freeProviderMessage')}</p>
      </div>
    )
  }, [])

  return (
    <div className='page'>
      {paymentLinkLoading && (
        <div className='flex flex-col gap-5'>
          <Skeleton className='!h-36' count={5} />
        </div>
      )}
      {paymentRequest && (
        <div>
          {changePaymentHeader}
          <div className='h-8' />
          <div>
            {paymentRequest?.paymentMethod === PaymentProviderTypeEnum.Free &&
              freeView}
            {paymentRequest?.paymentMethod === PaymentProviderTypeEnum.Stripe &&
              stripeView}
            {paymentRequest?.paymentMethod === PaymentProviderTypeEnum.PayPal &&
              paypalView}
          </div>
        </div>
      )}
    </div>
  )
}

export default ChangePaymentMethodInner

export const ROUTES = {
  root: '/',
  profile: '/profile',
  account: '/account',
  community: '/community',
  communityId: '/community/:id/',
  communityEdit: '/community/:id/edit/',
  communityMembers: '/community/:id/members',
  communityPartners: '/community/:id/partners',
  paymentsAndBilling: '/community/:id/payments-&-billing',
  settings: '/settings',
  faq: '/faq',
  contact: '/contact-us',
  helpCenter: '/help-center',
  getPaid: '/get-paid',
  detailsMenu: '/details-menu',
  communityMenu: '/community-menu',
  helpMenu: '/community-menu',
  notificationsMenu: '/notifications-menu',
  postCreate: '/post/create',
  promotionCreate: '/promotion/create',
  dashboardCreate: '/dashboard/create',
  communityCreate: '/community/create',
  communityPreview: '/community/:id/preview',
  communityCalendar: '/community/:id/calendar',
  communityPromotions: '/community/:id/promotions',
  communityDashboard: '/community/:id/dashboard',
  communityApproved: '/community/:id/approved',
  publishCommunity: '/community/:id/publish',
  setLaunchDate: '/community/:id/launch/set',
  changeLaunchDate: '/community/:id/launch/edit',
  confirmLaunchDate: '/community/:id/launch/confirm',
  launchDateCreated: '/community/:id/launch/created',
  communityLaunched: '/community/:id/launched',
  launchDateContactUs: '/community/:id/launch/contact-us',
  launchDateContactSent: '/community/:id/launch/contact-sent',
  setLaunchDateDisclaimer: '/community/:id/launch/disclaimer',
  changeLaunchDateDisclaimer: '/community/:id/launch/edit-disclaimer',
  communityCoupons: '/community/:id/coupons',
  noMatch: '*',

  // User
  createUser: '/user/:token',

  // Payment
  payment: '/payment',
  paymentTNC: '/payment/terms_and_conditions/:token',
  paymentCheckout: '/payment/checkout/:token',
  paymentFreeProvider: '/payment/free/:token',
  paymentChangePaymentMethod: '/payment/change_payment_method/:token',
  paymentDone: '/payment/done/:token',
  paymentRequestDone: '/payment/payment-request/done/:token',

  // Member Request
  memberRequest: '/member_request/:token',
  requestExpired: '/member_request/:token/request_expired',
  freeJoin: '/member_request/:token/free_join',
  unsubscribeCommunity: '/member_request/:token/unsubscribe',
  postUnsubscribeCommunity: '/member_request/:token/post-unsubscribe',

  // Member
  memberAuth: '/member',
  memberCreate: '/member_create',

  // Telegram Landing Page
  telegramPage: '/telegram',
  telegramLandingPage: '/telegram/community',
  telegramDeskLanding: '/telegram/desktop',
  telegramMobileLanding: '/telegram/mobile',
  telegramErrorNoToken: '/telegram/no_token',
  telegramApp: '',
}

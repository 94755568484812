import React, { useCallback, useMemo, useState } from 'react'
import { _t } from '@locales/index'
import { DOWNLOAD_TELEGRAM_DESKTOP_LINK, SUPPORT_EMAIL } from '@const/common'
import Logo from '@containers/navbar/components/Logo'
import CommunityPageDesk from '@containers/telegramPage/components/desktop/communityPageDesk'
import { telegramPageUtils } from '@containers/telegramPage/utils'
import FirstPageDesk from '@containers/telegramPage/components/desktop/InitialDesktopViewDescription'
import DownloadTgPage from '@containers/telegramPage/components/desktop/downloadTgAppDesk'
import { useSearchParams } from 'react-router-dom'
import { getProfileMediaByType } from '@containers/profile/utils'
import { ProfileMediaTypeEnum } from '@enums/media'
import { useGetMemberLink } from '@api/queries/memberLink'
import LogoNoText from '@assets/images/common/logo-white.svg'
import telegramLogoAndText from '@assets/images/socials/telegramLogoAndText.svg'

interface Props {}

const DownloadTelegramOptions: React.FC<Props> = () => {
  const [searchParams] = useSearchParams()
  const start = searchParams.get('start')
  const domain = searchParams.get('domain')

  const { data: memberLink } = useGetMemberLink(start || '', {
    enabled: Boolean(start),
    retry: 1,
  })

  const ownerProfileImageUrl = useMemo<string | undefined>(() => {
    if (memberLink?.memberRequest?.owner?.media) {
      const media = getProfileMediaByType(
        ProfileMediaTypeEnum.Profile,
        memberLink.memberRequest.owner.media,
      )
      if (media) {
        return media?.[0]?.url || LogoNoText
      }
    } else return LogoNoText
  }, [memberLink])

  const [doesNotHaveTelegram, setDoesNotHaveTelegram] = useState(false)
  const [downloadAppButtonClicked, setDownloadAppButtonClicked] =
    useState(false)

  const noHaveTG = useCallback(() => {
    setDoesNotHaveTelegram(true)
  }, [])

  const downloadTelegram = useCallback(() => {
    telegramPageUtils.openInNewTab(DOWNLOAD_TELEGRAM_DESKTOP_LINK)
    setTimeout(() => setDownloadAppButtonClicked(true), 2000)
  }, [])

  const renderOptions = useMemo(() => {
    // if user does not have telegram
    if (!start || !domain) {
      return
    }

    if (doesNotHaveTelegram && !downloadAppButtonClicked) {
      return (
        <DownloadTgPage
          onClickDownloadTelegram={downloadTelegram}
          domain={domain}
          start={start}
        />
      )
    }

    if (downloadAppButtonClicked)
      return <CommunityPageDesk domain={domain} start={start} />

    return <FirstPageDesk clickTG={noHaveTG} domain={domain} start={start} />
  }, [
    start,
    domain,
    doesNotHaveTelegram,
    downloadAppButtonClicked,
    downloadTelegram,
    noHaveTG,
  ])

  return (
    <div
      className={
        'bg-custom-black text-white w-[100vw] h-[100vh] flex flex-col items-center justify-center relative'
      }
    >
      <div className={'absolute top-10 left-10'}>
        <Logo />
      </div>

      <div
        className={'w-[150px] h-[150px] border-2 rounded-full overflow-auto'}
      >
        <img
          src={ownerProfileImageUrl}
          className={'rounded-full w-full'}
          alt={'owner'}
        />
      </div>
      <div className={'text-white flex flex-col items-center mt-5'}>
        <h1 className={'text-base'}>{_t('tgMyCommunityIsOnTG')}</h1>
        <img
          src={telegramLogoAndText}
          width={'280px'}
          height={'67px'}
          alt={'telegram'}
        />
      </div>

      {renderOptions}
      <a
        className={'mt-[174px]'}
        href={`mailto:${SUPPORT_EMAIL}?subject=I have issue connect my telegram account`}
      >
        <h1>{_t('connectSupport')}</h1>
      </a>
    </div>
  )
}

export default DownloadTelegramOptions
